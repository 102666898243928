import { useState, useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Container } from '@mui/material';
import moment from 'moment';
import MapCustomTabs from '../../../app/atoms/ButtonTabs/MapCustomTabs';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';
import { executeACGAction, updateScreenIdentifiers } from '../../../app/store/slice';
import useGetState from '../../../app/utils/hooks/useGetState';
import { _noop } from '../../../app/utils/common';
import { reGroup } from "../../../app/utils/helper";
import '../Admin/ClientManagement/index.scss';
import DrawerDropdown from '../../../app/atoms/DrawerDropdown';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import GBDashboard from './Logs/Config';
import Filter from './FilterConfg';
import ReportTypeFilter from './ReportTypeConfigFIlter';
import GoldenlistFilter from './GoldenBatchListConfigFIlter';
import LoadParameters from './LoadConfig';
import GoldenBatchList from './GoldenBatchList/Config';
import QualityAttributes from './QualityAttributes/Config';
import EditConfig from './QualityAttributes/BulkEdit';
import SnackbarAlert from '../../atoms/Snackbar';
import { Chart, registerables, ArcElement } from "chart.js";
Chart.register(...registerables);
Chart.register(ArcElement);
import CreateGoldenBatchConfig from './CreateConfig';
import { renameObjKeys } from '../Login/helper';
import Label from '../../atoms/Label/index';
import DropDown from '../../atoms/Dropdown';
const BQStabs = ['Golden Batch', 'Golden Batches List', 'Parameter Limit Update'];
const FBEtabs = ['Golden Batch', 'Golden Batches List'];
const options = {
  DEFAULT: {
    message: '',
    open: false,
    type: ''
  }
};

const Dateformat = {
  YYYY_MM_DD: 'yyyy-mm-dd',
  YYYY_MM_DD_HH_MM_SS: 'yyyy-mm-dd hh:mm:ss',
  YYYY_MM_DD_HH_MM_SS_L: 'yyyy-mm-ddTHH:MM:ss.l',
}

const SystemConfiguration = () => {
  const [currTab, setTabChange] = useState(0);
  const [radioValue, setRadioValue] = useState('All');
  const [drawerHeader, setDrawerHeader] = useState('Create');
  const [alertType, setAlertType] = useState('');
  const [clientList, setClientList] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [createValue, setCreateValue] = useState('');
  const [appliedFilters, setAppliedFilters] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [clearGBLimitFilter, setClearGBLimitFilter] = useState(false);
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const [plantKey, setPlantKey] = useState<any>(5);
  const [machineModelKey, setMachineModelKey] = useState<any>(11);
  const [machineModelName, setMachineModelName] = useState<any>('BQS');
  const [status, setStatus] = useState('Active');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [defVal, setDefVal] = useState<any>([]);
  const [hSliderT, setHSliderT] = useState<any>([]);
  const [vSliderT, setVSliderT] = useState<number>(0);
  const [vSliderB, setVSliderB] = useState<number>(0);
  const [serialData, setSerialData] = useState<any>([]);
  const [monitoringProductData, setMonitoringProductData] = useState<any>([]);
  const [recipeData, setRecipeData] = useState<any>([]);
  const [batchData, setBatchData] = useState<any>([]);
  const [parameterData, setParameterData] = useState<any>([]);
  const [selectedSerial, setSelectedSerial] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedBatch, setSelectedBatch] = useState('');
  const [selectedRecipe, setSelectedRecipe] = useState('');
  const [selectedParameter, setSelectedParameter] = useState('');
  const [parameterName, setParameterName] = useState('');
  const [chart1Page, setChart1Page] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [configPlants, setConfigPlants] = useState<any>([]);
  const [configMachineModel, setConfigMachineModel] = useState<any>([]);
  const [configMachineParams, setConfigMachineParams] = useState<any>([]);
  const [configMachineRecipes, setConfigMachineRecipes] = useState<any>([]);
  const [configMachineProcess, setConfigMachineProcess] = useState<any>([]);
  const [configMachineSubProcess, setConfigMachineSubProcess] = useState<any>([]);
  const [configBatches, setConfigBatches] = useState<any>([]);
  const [configMasterBatches, setConfigMasterBatches] = useState<any>([]);
  const [reportType, setReportType] = useState([{ 'code': 1, name: 'BQS' }, { 'code': 2, name: 'FBE' }])
  const [reportKey, setReportKey] = useState(1);
  const [productData, setProductsData] = useState<any[]>([]);
  const [goldenRecipe, setGoldenRecipe] = useState<any>([]);
  const [goldenRecipeKey, setGoldenRecipeKey] = useState<any>('');
  const [goldenBatchList, setGoldenBatchList] = useState<any>([]);
  const [qualityAttributeList, setQualityAttributeList] = useState<any>([]);
  const [filteredGoldenRecipeKey, setFilteredGoldenRecipeKey] = useState<any>('');
  const [filteredGoldenMachineModel, setFilteredGoldenMachineModel] = useState<any>('');
  const [limitParameters, setLimitParameters] = useState<any>([]);
  const [phasesTab, setPhasesTab] = useState<any>([]);
  const [autoFillProducts, setAutoFillProducts] = useState<any>([]);
  const [autoFillProcesss, setAutoFillProcess] = useState<any>([]);
  const [autoFillSubProcess, setAutoFillSubProcess] = useState<any>([]);
  const [autoFillRecipes, setAutoFillRecipes] = useState<any>([]);
  const [applyListFilter, setApplyListFilter] = useState<boolean>(false);
  const [filteredProcessKey, setFilteredProcessKey] = useState('');
  const [isGBLoading,setIsGBLoading]=useState(false)
  const [isQALoading,setIsQALoading]=useState(false)
  const dispatch = useDispatch();
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  
  const getCreateValue = (ele: string) => {
    setFilteredProcessKey('');
    setApplyListFilter(false);
    setGoldenRecipeKey('');
    setFilteredGoldenRecipeKey('');
    setSelectedRow([]);
    setDrawerHeader('Create');
    if (ele === "Define Golden Batch") {
      setCreateValue("Golden Batch");
    } else {
      setCreateValue(ele);
    }
    setCreateDrawerOpen(true);
  };
  const setCurrTab = (val: number) => {
    setQualityAttributeList([]);
    clearGBLimit();
    if (val === 1) setDrawerHeader('Create');
    setTabChange(val);
    if (radioValue == 'All' && currTab === 1) {
      setRadioValue('All');
    }
  };

  useEffect(() => {
    clearGBLimit();
    getLimitParameters();
    getGoldenBatchListBQS(-1, -1, -1, 11);
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA,
        uniqueScreenIdentifier: {
          goldenbatchAdded: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.goldenbatchAdded,
          data: []
        },

      })
    )
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_FBE,
        uniqueScreenIdentifier: {
          goldenbatchAddedFBE: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.goldenbatchAddedFBE,
          data: []
        },

      })
    )
  }, []);

  const getLimitParameters = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'GET',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_LIMIT_PARAMETERS,
        },
        uniqueScreenIdentifier: { gbLimit: true },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS]?.gbLimit) {
      const rptsData = state?.[STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS]?.body?.data;
      setLimitParameters([
        ...rptsData?.map((item: any) => ({ code: item.GBLimitTypesCodes, name: item.GBLimitTypes, key: item.GBLimitTypesKey }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS,
          uniqueScreenIdentifier: {
            gbLimit: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_LIMIT_PARAMETERS]?.gbLimit]);

  const getGoldenBatchListBQS = (key1: any, key2: any, key3: any, key4: any) => {
    setIsGBLoading(true)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_LIST_BQS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey ? machineModelKey : key4,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2 ? key2 : -1,
            "MachineKey": key3 ? key3 : -1

          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_BQS
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_BQS]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_BQS]?.body?.data?.map((item: any) => {
        return item;
      });
      setGoldenBatchList(list);
      setIsGBLoading(false)
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_BQS]]);

  const getGoldenBatchListFBE = (key1: any, key2: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_LIST_FBE,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": key1,
            "ProcessKey": key2 ? key2 : -1,
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_FBE
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_FBE]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_FBE]?.body?.data?.map((item: any) => {
        return item;
      });
      setGoldenBatchList(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_LIST_FBE]]);

  const getGoldenRecipeBQS = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_RECIPE_BQS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": -1
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_RECIPE_BQS
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_RECIPE_BQS]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_RECIPE_BQS]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { RecipeKey: 'code', RecipeName: 'name', BatchKey: 'code2' });
        return newVal;
      });
      setGoldenRecipe(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_RECIPE_BQS]]);

  const getQualityAttributesBQS = (key: any) => {
    setIsQALoading(true)
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_QA_BQS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "RecipeKey": key ? key : -1
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_QA_BQS
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_BQS]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_BQS]?.body?.data?.map((item: any) => {
        return item;
      });
      setQualityAttributeList(list);
      setIsQALoading(false)
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_BQS]]);

  const getQualityAttributesFBE = (key1: any, key2: any, key3: any, key4: any = null, key5: any = null, key6: any = null, key7: any = null) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_QA_FBE,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "CustProductKey": key1,
            "SubProcessKey": key2,
            "RecipeKey": key3,
            'PhaseKey': -1,
            "AutofillProcessKey": key4,
            "AutofillCustProductKey": key5,
            "AutofillSubProcessKey": key6,
            "AutofillRecipeKey": key7
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_QA_FBE
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_FBE]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_FBE]?.body?.data?.map((item: any) => {
        return item;
      });
      setQualityAttributeList(reGroup(list, 'PhaseKey'));
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_QA_FBE]]);

  const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

  useEffect(() => {
    setParameterName(parameterData.find((i: any) => { return i.code === selectedParameter })?.name);
  }, [selectedParameter]);

  const navigateTab = () => {
    switch (currTab) {

      case 0:
        return (
          <GBDashboard
            selectedRecipe={recipeData.find((item:any)=>item.code==selectedRecipe)}
            selectedBatch={batchData.find((item:any)=>item.code==selectedBatch)}
            appliedFilters={appliedFilters}
            clearFilter={clearFilter}
            setEditDrawerOpen={setCreateDrawerOpen}
            setCreateValue={setCreateValue}
            setDrawerHeader={setDrawerHeader}
            chart1Page={chart1Page}
            updatePage={updatePage}
            totalPages={totalPages}
            clearAppliedFilter={clearAppliedFilter}
            parameterName={parameterName}
            selectedSerial={selectedSerial}
            setDefVal={setDefVal}
            defVal={defVal}
            hSliderT={hSliderT}
            setHSliderT={setHSliderT}
            setVSliderB={setVSliderB}
            setVSliderT={setVSliderT}
            vSliderB={vSliderB}
            vSliderT={vSliderT}
            machineModelName={machineModelName}
          />
        )
      case 1:
        return (
          <GoldenBatchList
            currTab={currTab}
            setCurrTab={setCurrTab}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            setSnackbarOpts={setSnackbarOptions}
            setAlertType={setAlertType}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setDrawerHeader={setDrawerHeader}
            setCreateValue={setCreateValue}
            reportKey={reportKey}
            goldenBatchList={goldenBatchList}
            setReportKey={setReportKey}
            setGoldenRecipeKey={setGoldenRecipeKey}
            getGoldenBatchListBQS={getGoldenBatchListBQS}
            getGoldenBatchListFBE={getGoldenBatchListFBE}
            setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
            getQualityAttributesBQS={getQualityAttributesBQS}
            getQualityAttributesFBE={getQualityAttributesFBE}
            machineModelName={machineModelName}
            plantKey={plantKey}
            machineModelKey={machineModelKey}
            setPhasesTab={setPhasesTab}
            setAutoFillProducts={setAutoFillProducts}
            setAutoFillProcess={setAutoFillProcess}
            setAutoFillSubProcess={setAutoFillSubProcess}
            setAutoFillRecipes={setAutoFillRecipes}
            phasesTab={phasesTab}
            setApplyListFilter={setApplyListFilter}
            applyListFilter={applyListFilter}
            setFilteredProcessKey={setFilteredProcessKey}
            getProducts={getProducts}
            isLoading={isGBLoading}
            getProcess={getProcess}
          />
        )

      case 2:
        return (
          <QualityAttributes
            currTab={currTab}
            setCurrTab={setCurrTab}
            setClearFilter={setClearGBLimitFilter}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            setSnackbarOpts={setSnackbarOptions}
            setAlertType={setAlertType}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setDrawerHeader={setDrawerHeader}
            setCreateValue={setCreateValue}
            reportKey={reportKey}
            qualityAttributeList={qualityAttributeList}
            setReportKey={setReportKey}
            clearFilter={clearGBLimitFilter}
            clearGBLimit={clearGBLimit}
            setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
            isLoading={isQALoading}
          />
        )
      default:
        return (
          <GoldenBatchList
            currTab={currTab}
            setCurrTab={setCurrTab}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            setSnackbarOpts={setSnackbarOptions}
            setAlertType={setAlertType}
            setCreateDrawerOpen={setCreateDrawerOpen}
            setDrawerHeader={setDrawerHeader}
            setCreateValue={setCreateValue}
            reportKey={reportKey}
            goldenBatchList={goldenBatchList}
            setReportKey={setReportKey}
            setGoldenRecipeKey={setGoldenRecipeKey}
            getGoldenBatchListBQS={getGoldenBatchListBQS}
            getGoldenBatchListFBE={getGoldenBatchListFBE}
            setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
            getQualityAttributesBQS={getQualityAttributesBQS}
            getQualityAttributesFBE={getQualityAttributesFBE}
            machineModelName={machineModelName}
            plantKey={plantKey}
            machineModelKey={machineModelKey}
            setPhasesTab={setPhasesTab}
            setAutoFillProducts={setAutoFillProducts}
            setAutoFillProcess={setAutoFillProcess}
            setAutoFillSubProcess={setAutoFillSubProcess}
            setAutoFillRecipes={setAutoFillRecipes}
            phasesTab={phasesTab}
            setApplyListFilter={setApplyListFilter}
            applyListFilter={applyListFilter}
            setFilteredProcessKey={setFilteredProcessKey}
          />
        )
    }
  };

  useEffect(() => {
    getMachineSerial('Active', null, null, plantKey, machineModelKey ,machineModelName);
    getPlant();
    getProducts(5, 11, 'BQS_RETROFIT');
    getGoldenRecipeBQS();
  }, [])

  useEffect(() => {
    if (localStorage.getItem("token") !== null && state[STORE_KEYS.USER_DTL] !== null && appliedFilters) {
      if (status === "Active" && appliedFilters) {
        const interval = setInterval(() => getGoldenBatchData(chart1Page), 60000);
        return () => clearInterval(interval);
      } else if (appliedFilters) {
        getGoldenBatchData(chart1Page)
      }
    }
  }, [appliedFilters, status])

  const getGoldenBatchData = async (pno: number) => {
    machineModelName.includes("BQS") ? 
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GOLDEN_BATCH_DATA,
          reqObj: {
            "BatchKey": parseInt(selectedBatch),
            "MMParamKey": selectedParameter,
            "PageNo": pno === 0 ? 1 : pno
          }
        },
        uniqueScreenIdentifier: { goldenbatchAdded: true },
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA
      }) 
    ) : 
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GOLDEN_BATCH_DATA_FBE,
          reqObj: {
            "BatchStatus": status,
            "MachineKey": selectedSerial,
            "MMParamKey": selectedParameter,
            "PageNo": pno === 0 ? 1 : pno,
            "BatchKey": parseInt(selectedBatch),
          }
        },
        uniqueScreenIdentifier: { goldenbatchAddedFBE: true },
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_FBE
      }) 
    )
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data && selectedParameter !== "") {
      const gbData = state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data;
      const data: any = state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data : [];
      const newStoreData: any = []
      if (gbData.length === 0) setChart1Page(1);
      setTotalPages(gbData[0]?.TotalPages);
      gbData.slice(0).reverse()?.map((item: any) => {
        // Replace 'T' and '.000Z' to get "YYYY-MM-DD HH:mm:ss"
        const dateTimeStr = item?.EventTime.replace('T', ' ').replace('.000Z', '');
      
        // Split the date and time components
        const [datePart, timePart] = dateTimeStr.split(' ');
      
        // Split the date part into components
        const [year, month, day] = datePart.split('-');
      
        // Reformat the date to "DD/MM/YYYY HH:mm:ss"
        const formattedDate = `${day}/${month}/${year} ${timePart}`;
      
        newStoreData.push({
          date: formattedDate,
          upperLimit: item?.OptimumUpperLimit,
          lowerLimit: item?.OptimumLowerLimit,
          actual: item?.ActualValue,
          machineRunStatus: item?.MachineRunStatus,
          alarmAndAlerts: item?.AlarmAvail ? item?.AlarmAvail.split('-')[0] : '',
          isAlarm: item?.AlarmAvail && item?.AlarmAvail.split('-')[1] === "AM" ? true : false
        });
      });      

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GOLDEN_BATCH_DATA,
          uniqueScreenIdentifier: {
            goldenbatchAdded: false,
            data: newStoreData
          },

        })
      )
    }
  }, [state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data, selectedParameter]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data && selectedParameter !== "") {
      const gbData = state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data;
      const data: any = state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data : [];
      const newStoreData: any = []
      if (gbData.length === 0) setChart1Page(1);
      setTotalPages(gbData[0]?.TotalPages);
      gbData.slice(0).reverse()?.map((item: any, index: number) => {
                // Replace 'T' and '.000Z' to get "YYYY-MM-DD HH:mm:ss"
                const dateTimeStr = item?.EventTime.replace('T', ' ').replace('.000Z', '');
      
                // Split the date and time components
                const [datePart, timePart] = dateTimeStr.split(' ');
              
                // Split the date part into components
                const [year, month, day] = datePart.split('-');
              
                // Reformat the date to "DD/MM/YYYY HH:mm:ss"
                const formattedDate = `${day}/${month}/${year} ${timePart}`;
        newStoreData.push({
          date: formattedDate,
         // date: item?.EventTime.replace('T', ' ').replace('.000Z', ''),
          // date: moment.utc(item?.EventTime.replace('T',' ').replace('.000Z','')).format("DD/MM/YYYY HH:mm:ss"),
          upperLimit: item?.OptimumUpperLimit,
          lowerLimit: item?.OptimumLowerLimit,
          actual: item?.ActualValue,
          // batchStatus: item?.MachineStaus,
          machineRunStatus: item?.MachineRunStatus,
          alarmAndAlerts: item?.AlarmAvail ? item?.AlarmAvail.split('-')[0] : '',
          isAlarm:  item?.AlarmAvail && item?.AlarmAvail.split('-')[1] === "AM" ? true : false,
          phase: item?.PhaseKey,
          phaseName: item?.PhaseName,
        })
      })

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_FBE,
          uniqueScreenIdentifier: {
            goldenbatchAddedFBE: false,
            data: newStoreData
          },

        })
      )
    }
  }, [state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data, selectedParameter]);

  const getMachineSerial = (data1:any, data2:any, data3:any, pk:any, mmKey: any, mmName: any) => {
    const payload = mmName.includes("BQS") ? {
      "LoggedUserKey": loggedInUser,
      "BatchStatus": data1,
      "FromDate": data1 === "Active" ? null : data2,
      "ToDate": data1 === "Active" ? null : data3
    } : {
      "LoggedUserKey": loggedInUser,
      "PlantKey": pk,
      "MachineModelKey": mmKey,
      "BatchStatus": data1,
      "FromDate": data1 === "Active" ? null : data2,
      "ToDate": data1 === "Active" ? null : data3
    } 
    if(mmName.includes("BQS")) {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_GB_SERIAL_DATA,
          reqObj: payload
        },
        uniqueScreenIdentifier: { gbSerial: true },
        storeKey: ACTION_CODES.GET_GB_SERIAL_DATA
      })
    );
    } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_SERIAL_DATA_FBE,
            reqObj: payload
          },
          uniqueScreenIdentifier: { gbSerial: true },
          storeKey: ACTION_CODES.GET_GB_SERIAL_DATA_FBE
        })
      );
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_SERIAL_DATA]?.gbSerial) {
      const rptsData = state?.[STORE_KEYS.GET_GB_SERIAL_DATA]?.body?.data;
      setSerialData([
        ...rptsData?.map((item: any) => ({ code: item.MachineKey, name: item.LineMachineName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_SERIAL_DATA,
          uniqueScreenIdentifier: {
            gbSerial: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_SERIAL_DATA]?.gbSerial]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_SERIAL_DATA_FBE]?.gbSerial) {
      const rptsData = state?.[STORE_KEYS.GET_GB_SERIAL_DATA_FBE]?.body?.data;
      setSerialData([
        ...rptsData?.map((item: any) => ({ code: item.MachineKey, name: item.LineMachineName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_SERIAL_DATA_FBE,
          uniqueScreenIdentifier: {
            gbSerial: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_SERIAL_DATA_FBE]?.gbSerial]);

  const fetchMontoringProducts = (key: any, mmName:any) => {
    if(mmName.includes("BQS")) {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_GB_PRODUCT_DATA,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "BatchStatus": status,
            "MachineKey": key,
            "FromDate": status === "Active" ? null : from,
            "ToDate": status === "Active" ? null : to
          }
        },
        uniqueScreenIdentifier: { gbProduct: true },
        storeKey: STORE_KEYS.GET_GB_PRODUCT_DATA
      })
    ); } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_PRODUCT_DATA_FBE,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": plantKey,
              "MachineModelKey": machineModelKey,
              "MachineKey": key,
              "FromDate": status === "Active" ? null : from,
              "ToDate": status === "Active" ? null : to
            }
          },
          uniqueScreenIdentifier: { gbProduct: true },
          storeKey: STORE_KEYS.GET_GB_PRODUCT_DATA_FBE
        })
      ); 
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_PRODUCT_DATA]?.gbProduct) {
      const rptsData = state?.[STORE_KEYS.GET_GB_PRODUCT_DATA]?.body?.data;
      setMonitoringProductData([
        ...rptsData?.map((item: any) => ({ code: item.CustProductKey, name: item.ProductName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_PRODUCT_DATA,
          uniqueScreenIdentifier: {
            gbProduct: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_PRODUCT_DATA]?.gbProduct]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_PRODUCT_DATA_FBE]?.gbProduct) {
      const rptsData = state?.[STORE_KEYS.GET_GB_PRODUCT_DATA_FBE]?.body?.data;
      setMonitoringProductData([
        ...rptsData?.map((item: any) => ({ code: item.CustProductKey, name: item.ProductName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_PRODUCT_DATA_FBE,
          uniqueScreenIdentifier: {
            gbProduct: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_PRODUCT_DATA_FBE]?.gbProduct]);

  const getPlant = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
        return newVal;
      });
      setConfigPlants(list);
      getMachineModel(5);
    }
  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_PLANT_LIST]]);

  const getProducts = (PKey: any, MMKey: any, type: string) => {
    if (type.includes('BQS')) {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": PKey,
              "MachineModelKey": MMKey
            }
          },
          uniqueScreenIdentifier: { bqsProducts: true },
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS
        })
      );
    } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": PKey,
              "MachineModelKey": MMKey
            }
          },
          uniqueScreenIdentifier: { fbeProducts: true },
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE
        })
      );
    }

  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS]?.bqsProducts) {
      const result = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS]?.body?.data;
      const list = result?.map((item: any) => {
        const newVal = renameObjKeys(item, { CustProductKey: 'code', ProductName: 'name' });
        return newVal;
      });
      setProductsData(list);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS,
          uniqueScreenIdentifier: { bqsProducts: false }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_BQS]?.bqsProducts]);

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE]?.fbeProducts) {
      const result = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE]?.body?.data;
      const list = result?.map((item: any) => {
        const newVal = renameObjKeys(item, { CustProductKey: 'code', ProductName: 'name' });
        return newVal;
      });
      setProductsData(list);
      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE,
          uniqueScreenIdentifier: { fbeProducts: false },
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PRODUCT_LIST_FBE]?.fbeProducts]);

  const getMachineModel = (key: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": key
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { MachineModelKey: 'code', MachineModelName: 'name', MachineModelCode: 'alias' });
        return newVal;
      });
      setConfigMachineModel(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINEMODEL_LIST]])

  const getMachine = (key1: any, key2: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { MachineKey: 'code', LineMachineName: 'name' });
        return newVal;
      });
      setConfigMachineParams(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_MACHINE_LIST]])

  const getFBEMachine = (key1: any, key2: any, from: any, to: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2,
            "FromDate": from.toLocaleString().split(',')[0],
            "ToDate": to.toLocaleString().split(',')[0]
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { MachineKey: 'code', LineMachineName: 'name' });
        return newVal;
      });
      setConfigMachineParams(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MACHINE_LIST]])

  const getRecipes = (key: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { RecipeKey: 'code', RecipeName: 'name' });
        return newVal;
      });
      setConfigMachineRecipes(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_RECIPES_LIST]])

  const getFBERecipes = (key1: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "Type": selectedRow?.length !== 0 ? "Edit" : "Add"
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { RecipeKey: 'code', RecipeName: 'name' });
        return newVal;
      });
      setConfigMachineRecipes(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_RECIPES_LIST]])

  const getBatchesLot = (key1: any, key2: any, from: any, to: any, key3: any, key4: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2,
            "FromDate": from,
            "ToDate": to,
            "MachineKey": key3,
            "BatchNumber": key4,
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { BatchKey: 'code', BatchNo: 'name' });
        return newVal;
      });
      setConfigBatches(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LOT_LIST]])

  const getProcess = (key1: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : null
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { ProcessKey: 'code', ProcessName: 'name' });
        return newVal;
      });
      setConfigMachineProcess(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_PROCESS_LIST_FBE]])

  const getSubProcess = (key1: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "ProcessKey": key1
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { SubProcessKey: 'code', SubProcessName: 'name' });
        return newVal;
      });
      setConfigMachineSubProcess(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_SUB_PROCESS_LIST_FBE]])

  const getBatches = (key1: any, key2: any, key3: any, from: any, to: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST,
          reqObj: {
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2,
            "MachineKey": key3 ? key3 : -1,
            "FromDate": from.toLocaleString().split(',')[0],
            "ToDate": to.toLocaleString().split(',')[0]
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { BatchKey: 'code', BatchNo: 'name' });
        return newVal;
      });
      setConfigBatches(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_BATCHES_LIST]])

  const getMasterBatches = (key1: any, key2: any, from: any, to: any, key3: any,) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "PlantKey": plantKey,
            "MachineModelKey": machineModelKey,
            "CustProductKey": key1 ? key1 : -1,
            "RecipeKey": key2,
            "MachineKey": key3 ? key3 : -1,
            "FromDate": from.toLocaleString().split(',')[0],
            "ToDate": to.toLocaleString().split(',')[0]
          }
        },
        storeKey: STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST
      })
    );
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST]?.body?.data) {
      const list = state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { BatchNumber: 'code', BatchNumberValue: 'name' });
        return newVal;
      });
      setConfigMasterBatches(list);
    }

  }, [state?.[STORE_KEYS.API_GET_GOLDENBATCH_CONFIG_FILTER_FBE_MASTER_BATCHES_LIST]])

  const fetchRecipe = (key: any, mmName: any) => {
    if(mmName.includes("BQS")) {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_GB_RECIPE_DATA,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MachineKey": selectedSerial,
            "CustProductKey": key,
            "BatchStatus": status,
            "FromDate": status === "Active" ? null : from,
            "ToDate": status === "Active" ? null : to,

          }
        },
        uniqueScreenIdentifier: { gbRecipe: true },
        storeKey: STORE_KEYS.GET_GB_RECIPE_DATA
      })
    );
    } else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_RECIPE_DATA_FBE,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": plantKey,
              "MachineModelKey": machineModelKey,
              "MachineKey": selectedSerial,
              "CustProductKey": key,
              "BatchStatus": status,
              "FromDate": status === "Active" ? null : from,
              "ToDate": status === "Active" ? null : to,
  
            }
          },
          uniqueScreenIdentifier: { gbRecipe: true },
          storeKey: STORE_KEYS.GET_GB_RECIPE_DATA_FBE
        })
      );
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_RECIPE_DATA]?.gbRecipe) {
      const rptsData = state?.[STORE_KEYS.GET_GB_RECIPE_DATA]?.body?.data;
      setRecipeData([
        ...rptsData?.map((item: any) => ({ code: item.RecipeKey, name: item.RecipeName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_RECIPE_DATA,
          uniqueScreenIdentifier: {
            gbRecipe: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_RECIPE_DATA]?.gbRecipe]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_RECIPE_DATA_FBE]?.gbRecipe) {
      const rptsData = state?.[STORE_KEYS.GET_GB_RECIPE_DATA_FBE]?.body?.data;
      setRecipeData([
        ...rptsData?.map((item: any) => ({ code: item.RecipeKey, name: item.RecipeName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_RECIPE_DATA_FBE,
          uniqueScreenIdentifier: {
            gbRecipe: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_RECIPE_DATA_FBE]?.gbRecipe]);

  const fetchBatch = (key: any, mmName: any) => {
    if(mmName.includes("BQS")) {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_GB_BATCH_FILTER_DATA,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "MachineKey": parseInt(selectedSerial),
            "CustProductKey": selectedProduct,
            "RecipeKey": parseInt(key),
            "BatchStatus": status,
            "FromDate": status === "Active" ? null : from,
            "ToDate": status === "Active" ? null : to,

          }
        },
        uniqueScreenIdentifier: { gbBatch: true },
        storeKey: STORE_KEYS.GET_GB_BATCH_FILTER_DATA
      })
    );} else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_BATCH_FILTER_DATA_FBE,
            reqObj: {
              "LoggedUserKey": loggedInUser,
              "PlantKey": plantKey,
              "MachineModelKey": machineModelKey,
              "MachineKey": parseInt(selectedSerial),
              "CustProductKey": selectedProduct,
              "RecipeKey": parseInt(key),
              "FromDate": status === "Active" ? null : from,
              "ToDate": status === "Active" ? null : to,
  
            }
          },
          uniqueScreenIdentifier: { gbBatch: true },
          storeKey: STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE
        })
      );
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA]?.gbBatch) {
      const rptsData = state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA]?.body?.data;
      setBatchData([
        ...rptsData?.map((item: any) => ({ code: item.BatchKey, name: item.BatchNo }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_BATCH_FILTER_DATA,
          uniqueScreenIdentifier: {
            gbBatch: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA]?.gbBatch]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE]?.gbBatch) {
      const rptsData = state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE]?.body?.data;
      setBatchData([
        ...rptsData?.map((item: any) => ({ code: item.BatchKey, name: item.BatchNo }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE,
          uniqueScreenIdentifier: {
            gbBatch: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_BATCH_FILTER_DATA_FBE]?.gbBatch]);

  const fetchParameters = (key: any, mmName: any) => {
    if(mmName.includes("BQS")) {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.GET_GB_PARAMAETER_FILTER_DATA,
          reqObj: {
            "MachineKey": parseInt(key),
          }
        },
        uniqueScreenIdentifier: { gbParameter: true },
        storeKey: STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA
      })
    );} else {
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.GET_GB_PARAMAETER_FILTER_DATA_FBE,
            reqObj: {
              "MachineKey": parseInt(machineModelKey),
            }
          },
          uniqueScreenIdentifier: { gbParameter: true },
          storeKey: STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA_FBE
        })
      );
    }
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA]?.gbParameter) {
      const rptsData = state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA]?.body?.data;
      setParameterData([
        ...rptsData?.map((item: any) => ({ code: item.MMParamKey, name: item.ParamName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA,
          uniqueScreenIdentifier: {
            gbParameter: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA]?.gbParameter]);

  useEffect(() => {
    if (state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA_FBE]?.gbParameter) {
      const rptsData = state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA_FBE]?.body?.data;
      setParameterData([
        ...rptsData?.map((item: any) => ({ code: item.MMParamKey, name: item.ParamName }))

      ]);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA_FBE,
          uniqueScreenIdentifier: {
            gbParameter: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.GET_GB_PARAMAETER_FILTER_DATA_FBE]?.gbParameter]);

  const clearAppliedFilter = () => {
    setDefVal([0, 0]);
    setHSliderT([0, 0]);
    setStatus('Active');
    setFrom(null);
    setTo(null);
    setSelectedSerial('');
    setSelectedProduct('');
    setMonitoringProductData([]);
    setRecipeData([]);
    setSelectedRecipe('');
    setSelectedBatch('');
    setBatchData([]);
    setParameterData([]);
    setSelectedParameter('');
    setTotalPages(0);
    setChart1Page(0);
    setAppliedFilters(false);
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA,
        uniqueScreenIdentifier: {
          goldenbatchAdded: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.goldenbatchAdded,
          data: []
        },

      })
    )
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_FBE,
        uniqueScreenIdentifier: {
          goldenbatchAddedFBE: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.goldenbatchAddedFBE,
          data: []
        },

      })
    )
  }

  const switchGraphFilter = () => {
    setDefVal([0, 0]);
    setHSliderT([0, 0]);
    setFrom(null);
    setTo(null);
    setSelectedSerial('');
    setRecipeData([]);
    setSelectedRecipe('');
    setSelectedBatch('');
    setBatchData([]);
    setParameterData([]);
    setSelectedParameter('');
    setTotalPages(0);
    setChart1Page(0);
    setAppliedFilters(false);
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA,
        uniqueScreenIdentifier: {
          goldenbatchAdded: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.goldenbatchAdded,
          data: []
        },

      })
    )
    dispatch(
      updateScreenIdentifiers({
        storeKey: STORE_KEYS.GOLDEN_BATCH_DATA_FBE,
        uniqueScreenIdentifier: {
          goldenbatchAddedFBE: !state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.goldenbatchAddedFBE,
          data: []
        },

      })
    )
  }

  const updatePage = (pageNo: number, operator: string, count: number) => {
    if (operator === '+') {
      setChart1Page(pageNo === 0 ? pageNo + count + 1 : pageNo + count);
      getGoldenBatchData(pageNo === 0 ? pageNo + count + 1 : pageNo + count);
    } else {
      setChart1Page(pageNo - count);
      getGoldenBatchData(pageNo - count);
    }

  }

  const clearGBLimit = () => {
    setClearGBLimitFilter(false);
    setFilteredGoldenMachineModel('');
    setQualityAttributeList([]);
  }
  return (
    <div className="dashboard PMEC-Dash goldbatch">
      <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
      <Container maxWidth="xl" style={{ marginTop: '60px' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <div style={{ flex: '80%', display: 'flex' }}>
            <div className="headinglabel mb10" style={{ marginBottom: '15px' }}>
              Golden Batch Dashboard
            </div>

            <div
              style={{
                flex: '10%',
                marginLeft: '20px',
                // marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
              className="dashboardDropdownDiv"
            >
              <div className='plantmodel' style={{ color: "#e0e0e0" }}>
                <Label label="Plant Name &nbsp; *" className={'CMlabelDiv'} />
                <DropDown
                  onChange={(e: any) => { setPlantKey(e.target.value); setConfigMachineModel([]); getMachineModel(e.target.value); setMachineModelKey(''); setQualityAttributeList([]); }}
                  className="inputDiv"
                  name="dropdownModel"
                  items={configPlants}
                  placeHolder="Select Plant"
                  value={plantKey}
                />
                <Label label="Machine Model &nbsp; *" className={'CMlabelDiv'} />
                <DropDown
                  onChange={(e: any) => {
                    clearAppliedFilter();
                    const mmName = configMachineModel.find((item: any) => item.code === e.target.value).name;
                    const mmAlias =  configMachineModel.find((item: any) => item.code === e.target.value).alias;
                    setCurrTab(0);
                    setGoldenBatchList([]);
                    setMachineModelKey(e.target.value);
                    getMachineSerial('Active', null, null, plantKey, e.target.value, mmName);
                    setMachineModelName(mmAlias);
                    getProducts(plantKey, e.target.value, configMachineModel.find((item: any) => item.code === e.target.value).name);
                    // getProcess(e.target.value);
                    if (configMachineModel.find((item: any) => item.code === e.target.value).name.includes('BQS')) { getGoldenBatchListBQS(-1, -1, -1, e.target.value) } else { getGoldenBatchListFBE(e.target.value, -1) }
                  }}
                  className="inputDiv"
                  name="dropdownModel"
                  items={configMachineModel}
                  placeHolder="Select Machine Model"
                  value={machineModelKey}
                />
              </div>
              <DrawerDropdown isDisabled={machineModelKey === '' ? true : false} click={getCreateValue} items={['Define Golden Batch']} />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '10px', zIndex: '0', position: 'relative' }}>
          <MapCustomTabs
            navigateTab={navigateTab}
            currTab={currTab}
            setCurrTab={setCurrTab}
            tabsArray={machineModelName.includes("BQS") ? BQStabs : FBEtabs}
            className="tabs"
            position="relative"
          // type="nonCurve"
          />
        </div>
      </Container>
      <RightDrawer
        alertType={createValue}
        handlerOpen={() => { }}
        handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); setConfigBatches([]); setConfigMachineParams([]); setConfigMachineRecipes([]); setConfigMachineSubProcess([]); }}
        status={createDrawerOpen}
        drawerHeader={`${drawerHeader} ${createValue}`}
        children={
          drawerHeader === 'Create' || drawerHeader === 'Edit' || drawerHeader === 'Add' || drawerHeader === 'View' && createDrawerOpen ? (
            createValue === 'Golden Batch' ?
              (<CreateGoldenBatchConfig
                machineModelKey={machineModelKey}
                machineModelName={machineModelName}
                productData={productData}
                configMachineParams={configMachineParams}
                configMachineRecipes={configMachineRecipes}
                configBatches={configBatches}
                getMachineModel={getMachineModel}
                getMachine={getMachine}
                getFBEMachine={getFBEMachine}
                getRecipes={getRecipes}
                getBatches={getBatches}
                getBatchesLot={getBatchesLot}
                drawerClose={() => { 
                  setCreateDrawerOpen(false);
                  setSelectedRow([]);
                  setConfigBatches([]);
                  setConfigMachineParams([]);
                  setConfigMachineRecipes([]);
                  setConfigMasterBatches([]);
                  setConfigMachineSubProcess([]);
                 }}
                currTab={currTab}
                setCurrTab={setCurrTab}
                setSnackbarOpts={setSnackbarOptions}
                getGoldenBatchListBQS={getGoldenBatchListBQS}
                getGoldenRecipeBQS={getGoldenRecipeBQS}
                selectedRow={selectedRow}
                getProcess={getProcess}
                getSubProcess={getSubProcess}
                configMachineSubProcess={configMachineSubProcess}
                setConfigMachineSubProcess={setConfigMachineSubProcess}
                configMachineProcess={configMachineProcess}
                setConfigMachineProcess={setConfigMachineProcess}
                setConfigMasterBatches={setConfigMasterBatches}
                setConfigMachineParams={setConfigMachineParams}
                configMasterBatches={configMasterBatches}
                getMasterBatches={getMasterBatches}
                getFBERecipes={getFBERecipes}
                setConfigBatches={setConfigBatches}
                setConfigMachineRecipes={setConfigMachineRecipes}
                getGoldenBatchListFBE={getGoldenBatchListFBE}
                isFilter={false}
                filteredProcessKey={filteredProcessKey}
                setFilteredProcessKey={setFilteredProcessKey}
                getProducts={getProducts}
              />)
              : createValue === 'Limit Config' ?
                (
                  <EditConfig
                    drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setConfigBatches([]); setConfigMachineParams([]); setConfigMachineRecipes([]); }}
                    currTab={currTab}
                    setDrawerHeader={setDrawerHeader}
                    drawerHeader={drawerHeader}
                    setCurrTab={setCurrTab}
                    selectedRow={selectedRow}
                    setSnackbarOpts={setSnackbarOptions}
                    qualityAttributeList={qualityAttributeList}
                    goldenRecipe={goldenRecipe}
                    plantKey={plantKey}
                    machineModelKey={machineModelKey}
                    machineModelName={machineModelName}
                    filteredGoldenRecipeKey={filteredGoldenRecipeKey}
                    setQualityAttributeList={setQualityAttributeList}
                    limitParameters={limitParameters}
                    getQualityAttributesBQS={getQualityAttributesBQS}
                    setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
                    phasesTab={phasesTab}
                    autoFillProducts={autoFillProducts}
                    autoFillProcess={autoFillProcesss}
                    setAutoFillProcess={setAutoFillProcess}
                    autoFillSubProcess={autoFillSubProcess}
                    setAutoFillSubProcess={setAutoFillSubProcess}
                    autoFillRecipes={autoFillRecipes}
                    setAutoFillRecipes={setAutoFillRecipes}
                    goldenRecipeKey={goldenRecipeKey}
                    getQualityAttributesFBE={getQualityAttributesFBE}
                    getGoldenBatchListFBE={getGoldenBatchListFBE}
                  />
                )
                : createValue === 'Config Limit' ?
                  (<LoadParameters
                    drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setConfigBatches([]); setConfigMachineParams([]); setConfigMachineRecipes([]); }}
                    currTab={currTab}
                    setCurrTab={setCurrTab}
                    setSnackbarOpts={setSnackbarOptions}
                    reportType={reportType}
                    reportKey={reportKey}
                    setReportKey={setReportKey}
                    selectedRow={selectedRow}
                    goldenRecipe={goldenRecipe}
                    machineModelName={machineModelName}
                    setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
                    setGoldenRecipeKey={setGoldenRecipeKey}
                    goldenRecipeKey={goldenRecipeKey}
                    getQualityAttributesBQS={getQualityAttributesBQS}
                    getGoldenBatchListBQS={getGoldenBatchListBQS}
                    setClearGBLimitFilter={setClearGBLimitFilter}
                  />) : null
          ) : createValue === 'Parameter Limit Update Filter' ?
            (
              <ReportTypeFilter
                drawerClose={() => { setCreateDrawerOpen(false); }}
                currTab={currTab}
                setCurrTab={setCurrTab}
                setSnackbarOpts={setSnackbarOptions}
                reportType={reportType}
                reportKey={reportKey}
                setReportKey={setReportKey}
                createValue={createValue}
                goldenRecipe={goldenRecipe}
                goldenBatchList={goldenBatchList}
                setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
                filteredGoldenRecipeKey={filteredGoldenRecipeKey}
                getQualityAttributesBQS={getQualityAttributesBQS}
                configMachineModel={configMachineModel}
                filteredGoldenMachineModel={filteredGoldenMachineModel}
                setFilteredGoldenMachineModel={setFilteredGoldenMachineModel}
                setClearGBLimitFilter={setClearGBLimitFilter}
                machineModelKey={machineModelKey}
              />
            ) : createValue === 'Golden Batches Filter' && machineModelName.includes("BQS") ?
              (
                <GoldenlistFilter
                  drawerClose={() => { setCreateDrawerOpen(false); }}
                  currTab={currTab}
                  setCurrTab={setCurrTab}
                  setSnackbarOpts={setSnackbarOptions}
                  reportType={reportType}
                  reportKey={reportKey}
                  setReportKey={setReportKey}
                  createValue={createValue}
                  goldenRecipe={goldenRecipe}
                  setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
                  filteredGoldenRecipeKey={filteredGoldenRecipeKey}
                  getQualityAttributesBQS={getQualityAttributesBQS}
                  configMachineModel={configMachineModel}
                  filteredGoldenMachineModel={filteredGoldenMachineModel}
                  setFilteredGoldenMachineModel={setFilteredGoldenMachineModel}
                  setClearGBLimitFilter={setClearGBLimitFilter}
                  machineModelKey={machineModelKey}
                  machineModelName={machineModelName}
                />
              )
              : createValue === 'Golden Batches Filter' && !machineModelName.includes("BQS") ?
              (
                <CreateGoldenBatchConfig
                machineModelKey={machineModelKey}
                machineModelName={machineModelName}
                productData={productData}
                configMachineParams={configMachineParams}
                configMachineRecipes={configMachineRecipes}
                configBatches={configBatches}
                getMachineModel={getMachineModel}
                getMachine={getMachine}
                getFBEMachine={getFBEMachine}
                getRecipes={getRecipes}
                getBatches={getBatches}
                getBatchesLot={getBatchesLot}
                drawerClose={() => { 
                  setCreateDrawerOpen(false);
                  setSelectedRow([]);
                  setConfigBatches([]);
                  setConfigMachineParams([]);
                  setConfigMachineRecipes([]);
                  setConfigMasterBatches([]);
                  setConfigMachineSubProcess([]);
                 }}
                currTab={currTab}
                setCurrTab={setCurrTab}
                setSnackbarOpts={setSnackbarOptions}
                getGoldenBatchListBQS={getGoldenBatchListBQS}
                getGoldenRecipeBQS={getGoldenRecipeBQS}
                selectedRow={selectedRow}
                getProcess={getProcess}
                getSubProcess={getSubProcess}
                configMachineSubProcess={configMachineSubProcess}
                setConfigMachineSubProcess={setConfigMachineSubProcess}
                configMachineProcess={configMachineProcess}
                setConfigMachineProcess={setConfigMachineProcess}
                setConfigMasterBatches={setConfigMasterBatches}
                setConfigMachineParams={setConfigMachineParams}
                configMasterBatches={configMasterBatches}
                getMasterBatches={getMasterBatches}
                getFBERecipes={getFBERecipes}
                setConfigBatches={setConfigBatches}
                setConfigMachineRecipes={setConfigMachineRecipes}
                getGoldenBatchListFBE={getGoldenBatchListFBE}
                isFilter={true}
                setApplyListFilter={setApplyListFilter}
                filteredProcessKey={filteredProcessKey}
                setFilteredProcessKey={setFilteredProcessKey}
              />
              )
              : <Filter
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                chart1Page={chart1Page}
                status={status}
                setStatus={setStatus}
                serialData={serialData}
                batchData={batchData}
                setSerialData={setSerialData}
                setBatchData={setBatchData}
                recipeData={recipeData}
                setRecipeData={setRecipeData}
                parameterData={parameterData}
                setParameterData={setParameterData}
                selectedBatch={selectedBatch}
                setSelectedBatch={setSelectedBatch}
                selectedParameter={selectedParameter}
                setSelectedParameter={setSelectedParameter}
                selectedSerial={selectedSerial}
                setSelectedSerial={setSelectedSerial}
                selectedRecipe={selectedRecipe}
                setSelectedRecipe={setSelectedRecipe}
                fetchRecipe={fetchRecipe}
                fetchBatch={fetchBatch}
                fetchParameters={fetchParameters}
                clearAppliedFilter={clearAppliedFilter}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                drawerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); }}
                getGoldenBatchData={getGoldenBatchData}
                switchGraphFilter={switchGraphFilter}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                monitoringProductData={monitoringProductData}
                setMonitoringProductData={setMonitoringProductData}
                fetchMontoringProducts={fetchMontoringProducts}
                getMachineSerial={getMachineSerial}
                plantKey={plantKey}
                machineModelKey={machineModelKey}
                machineModelName={machineModelName}
              />
        }
      />
    </div>
  );
};

export default SystemConfiguration;

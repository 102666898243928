import React, { useEffect, useState } from 'react';
import { Container, Card } from '@mui/material';
import AirportShuttleOutlinedIcon from '@material-ui/icons/AirportShuttleOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import CloudOffOutlinedIcon from '@material-ui/icons/CloudOffOutlined';
import MachineManagementTopCard from '../../atoms/MachineMonitoringTopCard';
import BackArrowIcon from '../../../assets/images/BackArrowIcon.svg';
import CustomTableToolbar from '../../atoms/CustomTableToolbar';
import CustomTable from '../../atoms/CustomTable';
import MachineMonitoringDrawer from '../../atoms/MachineMonitoringDrawer';
import { useDispatch } from 'react-redux';
import { executeACGAction, resetErr, updateScreenIdentifiers } from '../../store/slice';
import { ACTION_CODES, STORE_KEYS } from '../../constants/apiConstants';
import useGetState from '../../utils/hooks/useGetState';
import { paths } from '../../constants/paths';
import { useHistory } from 'react-router-dom';
import SnackbarAlert from '../../atoms/Snackbar';
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    MARK_SUCCESS: {
        message: 'Machine marked as Favourite successfully!',
        open: true,
        type: 'success'
    },
    UNMARK_SUCCESS: {
        message: 'Machine unmarked as Favourite successfully!',
        open: true,
        type: 'success'
    }
};
const MachineDetail = () => {
    const [state, setState] = useState({
        right: false
    });
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [filterName, setFilterName] = useState<string>('');
    const radioFields = [
        { label: 'All', value: 'All' },
        // { label: 'Critical', value: 'Critical' },
        // { label: 'Failure', value: 'Failure' },
        { label: 'Online', value: 'Online' },
        { label: 'Offline', value: 'Offline' },
        { label: 'Favourites', value: 'Favourites' }
    ];
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [radioValue, setRadioValue] = useState('All');
    const [machineData, setMachineData] = useState<any>([]);
    const [machines, setMachines] = useState<any>([]);
    const stateObj = useGetState();
    const history = useHistory();
    const dispatch = useDispatch();
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [searchTxt, setSearchTxt] = useState<any>(null);
    const [srchMachines, setSrchMachines] = useState<any>([]);
    const resetSearch = () => {
        setSearchTxt('');
    };
    const handleRadioValue = (val: string) => {
        setRadioValue(val);
    };

    useEffect(() => {
        if (radioValue == 'Online' || radioValue == 'Offline') {
            const newData = [...machineData];
            let x = [];
            if (filterName !== '') {
                x = newData?.filter(
                    (ele: any) => ele?.ConnectivityStatus === radioValue && (ele?.MachineName?.includes(filterName) || ele?.MachineName.toLowerCase()?.includes(String(filterName).toLowerCase()))
                );
            } else {
                x = newData?.filter((ele: any) => ele?.ConnectivityStatus === radioValue);
            }
            setMachines(x);
        } else if (radioValue === 'Favourites') {
            const newData = [...machineData];
            let x = [];
            if (filterName !== '') {
                x = newData?.filter(
                    (ele: any) => ele?.FavoriteMachineFlag === 'Y' && (ele?.MachineName?.includes(filterName) || ele?.MachineName.toLowerCase()?.includes(String(filterName).toLowerCase()))
                );
            } else {
                x = newData?.filter((ele: any) => ele?.FavoriteMachineFlag === 'Y');
            }
            setMachines(x);
        } else if (radioValue === 'Failure') {
            const newData = [...machineData];
            let x = [];
            if (filterName !== '') {
                x = newData?.filter(
                    (ele: any) => ele?.MachineFailureStatus === 'Failure' && (ele?.MachineName?.includes(filterName) || ele?.MachineName.toLowerCase()?.includes(String(filterName).toLowerCase()))
                );
            } else {
                x = newData?.filter((ele: any) => ele?.MachineFailureStatus === 'Failure');
            }
            setMachines(x);
        } else if (radioValue === 'Critical') {
            const newData = [...machineData];
            let x = [];
            if (filterName !== '') {
                x = newData?.filter(
                    (ele: any) => ele?.MachineSeverityStatus === 'Critical' && (ele?.MachineName?.includes(filterName) || ele?.MachineName.toLowerCase()?.includes(String(filterName).toLowerCase()))
                );
            } else {
                x = newData?.filter((ele: any) => ele?.MachineSeverityStatus === 'Critical');
            }
            setMachines(x);
        } else {
            const newData = [...machineData];
            let x = [];
            if (filterName !== '') {
                x = newData?.filter((ele: any) => (ele?.MachineName?.includes(filterName) || ele?.MachineName.toLowerCase()?.includes(String(filterName).toLowerCase())));
            } else {
                x = [...newData];
            }
            setMachines(x);
        }
    }, [radioValue]);

    const getMachines = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: `${ACTION_CODES.GET_MACHINES}/All`
                },
                uniqueScreenIdentifier: { macRecd: true },
                storeKey: STORE_KEYS.MACHINES
            })
        );
    };
    const getSummary = () => {
        dispatch(
            executeACGAction({
                payload: {
                    requestType: 'GET',
                    urlPath: ACTION_CODES.MACHMON_SMRY
                },
                uniqueScreenIdentifier: { smryRecd: true },
                storeKey: STORE_KEYS.MACMON_SMRY
            })
        );
    };

    useEffect(() => {
        getSummary();
        getMachines();
    }, []);

    useEffect(() => {
        if (stateObj?.[STORE_KEYS.MACHINES]?.macRecd) {
            setFilterName('');
            setMachineData(stateObj?.[STORE_KEYS.MACHINES]?.body?.data);
            if (radioValue == 'Online' || radioValue == 'Offline') {
                const newData = [...stateObj?.[STORE_KEYS.MACHINES]?.body?.data];
                const x = newData?.filter((ele: any) => ele?.ConnectivityStatus === radioValue);
                setMachines(x);
            } else if (radioValue === 'Failure') {
                const newData = [...stateObj?.[STORE_KEYS.MACHINES]?.body?.data];
                const x = newData?.filter((ele: any) => ele?.MachineFailureStatus === 'Failure');
                setMachines(x);
            } else if (radioValue === 'Critical') {
                const newData = [...stateObj?.[STORE_KEYS.MACHINES]?.body?.data];
                const x = newData?.filter((ele: any) => ele?.MachineSeverityStatus === 'Critical');
                setMachines(x);
            } else if (radioValue === 'Favourites') {
                const newData = [...stateObj?.[STORE_KEYS.MACHINES]?.body?.data];
                const x = newData?.filter((ele: any) => ele?.FavoriteMachineFlag === 'Y');
                setMachines(x);
            } else {
                setMachines(stateObj?.[STORE_KEYS.MACHINES]?.body?.data);
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.MACHINES,
                    uniqueScreenIdentifier: {
                        macRecd: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.MACHINES]?.macRecd]);

    const handleFilterByName = (event: { target: { value: React.SetStateAction<string> } }) => {
        setFilterName(event.target.value);
        const changeCase = String(event.target.value).toLowerCase()
        if (event.target.value !== '') {
            const newData = [...machineData];
            if (radioValue === 'Favourites') {
                const x = newData?.filter(
                    (ele: any) => (ele?.MachineName?.toLowerCase().includes(changeCase) && ele?.FavoriteMachineFlag === 'Y') || (ele?.PlantName?.toLowerCase().includes(changeCase) && ele?.FavoriteMachineFlag === 'Y')
                );
                setMachines(x);
            } else if (radioValue === 'Online' || radioValue === 'Offline') {
                const x = newData?.filter(
                    (ele: any) =>
                        ele?.MachineName?.toLowerCase().includes(changeCase) && ele?.ConnectivityStatus === radioValue || (ele?.PlantName?.toLowerCase().includes(changeCase) && ele?.ConnectivityStatus === radioValue)
                );
                setMachines(x);
            } else if (radioValue === 'Failure') {
                const x = newData?.filter(
                    (ele: any) =>
                        ele?.MachineName?.toLowerCase().includes(changeCase) && ele?.MachineFailureStatus === 'Failure' || (ele?.PlantName?.toLowerCase().includes(changeCase) && ele?.MachineFailureStatus === 'Failure')
                );
                setMachines(x);
            } else if (radioValue === 'Critical') {
                const x = newData?.filter(
                    (ele: any) =>
                        ele?.MachineName?.toLowerCase().includes(changeCase) && ele?.MachineSeverityStatus === 'Critical'|| (ele?.PlantName?.toLowerCase().includes(changeCase) && ele?.MachineSeverityStatus === 'Critical')
                );
                setMachines(x);
            } else {
                const x = newData?.filter((ele: any) => ele?.MachineName?.toLowerCase().includes(changeCase) || (ele?.PlantName?.toLowerCase().includes(changeCase)));
                setMachines(x);
            }
        } else {
            const newData = [...machineData];
            if (radioValue === 'Favourites') {
                const x = newData?.filter((ele: any) => ele?.FavoriteMachineFlag === 'Y');
                setMachines(x);
            } else if (radioValue === 'Online' || radioValue === 'Offline') {
                const x = newData?.filter((ele: any) => ele?.ConnectivityStatus === radioValue);
                setMachines(x);
            } else if (radioValue === 'Failure') {
                const x = newData?.filter((ele: any) => ele?.MachineFailureStatus === 'Failure');
                setMachines(x);
            } else if (radioValue === 'Critical') {
                const x = newData?.filter((ele: any) => ele?.MachineSeverityStatus === 'Critical');
                setMachines(x);
            } else {
                setMachines(newData);
            }
        }
    };

    const handleRoute = (row: any, ele: any) => {
        setSrchMachines(stateObj?.[STORE_KEYS.MACHINES]?.body?.data);
        setSelectedRow(row);
        setCreateDrawerOpen(true);
    };

    const searchMachines = (event: any) => {
        setSearchTxt(event);
        const macs = stateObj?.[STORE_KEYS.MACHINES]?.body?.data;
        if (event !== null) {
            const newList = macs?.filter((item: any) =>
                item?.MachineName?.toLowerCase()?.includes(event?.MachineName?.toLowerCase())
            );
            setSrchMachines(newList);
        } else {
            setSrchMachines(macs);
        }
    };
    const closeDrawer = () => {
        setSelectedRow([]);
        setSrchMachines([]);
        setCreateDrawerOpen(false);
    };
    const resetInput = () => {
        setFilterName('');
        if (radioValue == 'Online' || radioValue == 'Offline') {
            const newData = [...machineData];
            const x = newData?.filter((ele: any) => ele?.ConnectivityStatus === radioValue);
            setMachines(x);
        } else if (radioValue === 'Favourites') {
            const newData = [...machineData];
            const x = newData?.filter((ele: any) => ele?.FavoriteMachineFlag === 'Y');
            setMachines(x);
        } else if (radioValue === 'Failure') {
            const newData = [...machineData];
            const x = newData?.filter((ele: any) => ele?.MachineFailureStatus === 'Failure');
            setMachines(x);
        } else if (radioValue === 'Critical') {
            const newData = [...machineData];
            const x = newData?.filter((ele: any) => ele?.MachineSeverityStatus === 'Critical');
            setMachines(x);
        } else {
            setMachines(machineData);
        }
    };
    const favClick = (newValue: any, markKey?: any, unMarkKey?: any) => {
        if (newValue !== 1) {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'DELETE',
                        urlPath: `${ACTION_CODES.UNMARK_FAV}/${unMarkKey}`
                    },
                    uniqueScreenIdentifier: { unMarked: true },
                    storeKey: STORE_KEYS.ADD_RPT
                })
            );
        } else {
            dispatch(
                executeACGAction({
                    payload: {
                        requestType: 'POST',
                        urlPath: ACTION_CODES.MARK_FAV,
                        reqObj: { machineKey: markKey }
                    },
                    uniqueScreenIdentifier: { marked: true },
                    storeKey: STORE_KEYS.ADD_LINE
                })
            );
        }
    };
    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ADD_RPT]?.unMarked) {
            if (stateObj?.[STORE_KEYS.ADD_RPT]?.statusCode === 200) {
                setSnackbarOptions(options.UNMARK_SUCCESS);
                getSummary();
                getMachines();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_RPT,
                    uniqueScreenIdentifier: {
                        unMarked: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.ADD_RPT]?.unMarked]);
    useEffect(() => {
        if (stateObj?.[STORE_KEYS.ADD_LINE]?.marked) {
            if (stateObj?.[STORE_KEYS.ADD_LINE]?.statusCode === 200) {
                setSnackbarOptions(options.MARK_SUCCESS);
                getSummary();
                getMachines();
            }
            dispatch(
                updateScreenIdentifiers({
                    storeKey: STORE_KEYS.ADD_LINE,
                    uniqueScreenIdentifier: {
                        marked: false
                    }
                })
            );
        }
    }, [stateObj?.[STORE_KEYS.ADD_LINE]?.marked]);
    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (stateObj?.err) {
            handleSnackbarError(stateObj?.err);
            dispatch(resetErr());
        }
    }, [stateObj?.err]);
    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" className="machineMonitoring">
                <div style={{ marginTop: 60}}>
                    {/* <img
                        style={{ cursor: 'pointer', height: '16px', marginTop: '6px' }}
                        src={BackArrowIcon}
                        alt="Arrow"
                        onClick={() => history.push(paths.MACHINE_MONITOR.path)}
                    /> */}
                    <h4 className="headinglabel" style={{ fontSize: '18px' }}>Machine Monitoring</h4>
                </div>
                <br />
                <MachineManagementTopCard
                    data={[
                        {
                            title: 'Total Machines',
                            count: stateObj?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.TotalMachines,
                            icon: AirportShuttleOutlinedIcon
                        },
                        // {
                        //     title: 'Critical',
                        //     count: stateObj?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.CriticalMachines,
                        //     icon: ReportProblemOutlinedIcon
                        // },
                        // {
                        //     title: 'Failure',
                        //     count: stateObj?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.TotalFailures,
                        //     icon: HighlightOffOutlinedIcon
                        // },
                        {
                            title: 'Online',
                            count: stateObj?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.OnlineMachines,
                            icon: CloudDoneOutlinedIcon
                        },
                        {
                            title: 'Offline',
                            count: stateObj?.[STORE_KEYS.MACMON_SMRY]?.body?.data?.[0]?.OffLineMachines,
                            icon: CloudOffOutlinedIcon
                        }
                    ]}
                    
                />
                <br />
                {/* {stateObj?.isLoading && (
                    <div className="loaderDiv">
                        <Loader />
                    </div>
                )} */}

                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '3px',
                        paddingRight: '3px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '10px 10px 0px 0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => { }}
                            numSelected={0}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            displayInput={true}
                            text={{
                                title: 'Machine Lists',
                                subTitle: `${machines?.length} machines`
                            }}
                            filterButton={false}
                            customButton={false}
                            radios={true}
                            radioValue={radioValue}
                            radioFields={radioFields}
                            onRadioChange={handleRadioValue}
                            resetInput={resetInput}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers={[]}
                    body={machines}
                    filteredUsers={machines}
                    filterName={''}
                    selected={[]}
                    order={''}
                    orderBy={''}
                    onRequestSort={() => { }}
                    onSelectAllClick={() => { }}
                    rowChange={() => { }}
                    rowCount={0}
                    onPageChange={() => { }}
                    page={0}
                    setPageNumber={() => { }}
                    selectRow={() => { }}
                    route={handleRoute}
                    isRequestPage={true}
                    isLoading={stateObj?.isLoading}
                    link={true}
                    type="machineList"
                    favClick={favClick}
                    noDataText={'No Machine data available'}
                />
            </Container>
            <MachineMonitoringDrawer
                handlerOpen={() => { }}
                handlerClose={closeDrawer}
                status={createDrawerOpen}
                data={selectedRow}
                block={true}
                searchTxt={searchTxt}
                setSearchTxt={searchMachines}
                resetInput={resetSearch}
                machines={srchMachines}
                setSelectedRow={setSelectedRow}
            />
        </div>
    );
};

export default MachineDetail;

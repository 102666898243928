import { useState, useEffect } from 'react';
import { Container } from '@mui/material'
import useGetState from '../../utils/hooks/useGetState';
import { useDispatch } from "react-redux";
import { _noop } from '../../utils/common';
import SnackbarAlert from '../../atoms/Snackbar';
import DrawerDropdown from '../../../app/atoms/DrawerDropdownNotification';
import './index.scss';
import MapCustomTabs from '../../atoms/ButtonTabs/MapCustomTabs';
import NotificationGroup from './NotificationGroup/Config';
import CreateNotificationGroup from './NotificationGroup/CreateConfig';
import { executeACGAction, reset, resetStoreKey, updateScreenIdentifiers } from "../../store/slice";
import LoadMembers from './NotificationGroup/LoadMembers';
import Filter from './CreateFilterConfig';
import { SCREEN_TITLES } from '../../constants/StringConstants';
import RightDrawer from '../../../app/atoms/tempDrawer/indexAlt';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../app/constants/apiConstants';
import NotificationService from './notificationService';
import AllNotifications from './Report';
import NotificationLogs from './NotificationLogs/Config';
import AcknowledgeNotification from './NotificationLogs/CreateConfig';
import { set } from 'date-fns';
import { error } from 'console';
import { renameObjKeys } from '../Login/helper';

const Notifications = () => {
  const dispatch = useDispatch();
  const tabs = ['Notifications', 'Notification Logs', 'Notification Groups'];
  const options = {
    DEFAULT: {
      message: '',
      open: false,
      type: ''
    },
    AD_SUCCESS: {
      message: `Notification acknowledged successfully!`,
      open: true,
      type: 'success'
    },
    AD_FAIL: {
      message: `Notification acknowledge failed!`,
      open: true,
      type: 'remark'
    }
  };
  const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
  const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
  const [radioValue, setRadioValue] = useState('All');
  const [drawerHeader, setDrawerHeader] = useState('Create dd');
  const [createValue, setCreateValue] = useState('');
  const [reload, setReload] = useState(false);
  const [dtlData, setDtlData] = useState<any>({});
  const [customersData, setCustomersData] = useState<any[]>([]);
  const [filterCustomerData, setFilterCustomersData] = useState<any[]>([]);
  const [modulesData, setModules] = useState<any[]>([]);
  const [ruleTypes, setRuleTypes] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
  const [newNotification, setNewNotification] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [filteredCustomer, setFilteredCustomer] = useState([]);
  const [filteredModule, setFilteredModule] = useState([]);
  const [filteredPlant, setFilteredPlant] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(false);
  const [nKey, setNKey] = useState('');
  const [currTab, setCurrTab] = useState(0);
  const [filteredReportType, setFilteredReportType] = useState(-1);
  const [filteredSeverity, setFilteredSeverity] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [reportType, setReportType] = useState([]);
  const [severity, setSeverity] = useState([]);
  const [machines, setMachines] = useState([]);
  const [notificationLogs, setNotificationLogs] = useState<any>([]);
  const [notificationHistory, setNotificationHistory] = useState<any>([]);
  let defaultStartDate = new Date();
  defaultStartDate = new Date(defaultStartDate.setDate(defaultStartDate.getDate() - 7));
  const [inDate, setInDate] = useState<any>(defaultStartDate);
  const [endDate, setEndDate] = useState<any>(new Date());
  const [historyFilteredReportType, setHistoryFilteredReportType] = useState(-1);
  const [historyFilteredSeverity, setHistoryFilteredSeverity] = useState([]);
  const [historyFilteredMachines, setHistoryFilteredMachines] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState('');
  const [ackSelectedNotification, setAckSelectedNotification] = useState([]);
  const [notificationDetails, setNotificationDetails] = useState([]);
  const state = useGetState();
  const token = state?.[STORE_KEYS.USER_DTL].token;
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  const [ackError, setAckError] = useState<any>([]);
  const [notificationGroupPlantList, setNotiGroupPlantList] = useState([]);
  const [notiFilterPlantList, setNotiFilterPlantList] = useState([]);
  const [notifilteredPlant,setNotiFilteredPlant]=useState(-1)
  const [isAllNotiLoading,setIsAllNotiLoading]=useState(false)
  const [isNotiGroupLoading,setIsNotiGroupLoading]=useState(false)
  const [isNotiLogsLoading,setIsNotiLogsLoading]=useState(false)
  const [moduleFilterOptions,setModuleFilterOptions]=useState([]);
  const [moduleFilterRadioValue,setModuleFilterRadioValue]=useState("FMEA")

  const handleModuleFilterRadioValue = (val: string) => {
    setModuleFilterRadioValue(val);
};

  useEffect(() => {
    clearFilter();
  }, [currTab])

  const getCreateValue = (ele: string) => {
    setDrawerHeader('Create');
    setNewNotification(true);
    setCreateValue(ele);
    setCreateDrawerOpen(true);
    setNKey('');
  };

  const getAllNotifications = (flag: boolean) => {
    setIsAllNotiLoading(true)
    const sk: any = [], mk: any = [];
    filteredSeverity.map((i: any) => sk.push(i.SeverityKey));
    filteredMachines.map((i: any) => mk.push(i.MachineKey));
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_ALL_NOTIFICATIONS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            // "RuleTypeKey": flag ? -1 : filteredReportType,
            "ModuleCode": moduleFilterRadioValue,
            "SeverityKeys": !sk.length || flag ? '-1' : sk.toString(),
            "PlantKeys": notifilteredPlant.toString(),
            "MachineKeys": !mk.length || flag ? '-1' : mk.toString()
          }
        },
        uniqueScreenIdentifier: { allNotifications: true },
        storeKey: STORE_KEYS.API_GET_ALL_NOTIFICATIONS
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_ALL_NOTIFICATIONS]?.allNotifications) {
      const rptsData = state?.[STORE_KEYS.API_GET_ALL_NOTIFICATIONS]?.body?.data;
      const setData = rptsData?.map((rpt: any, index: any) => {
        const record = { ...rpt }
        return record;
      });

      setNotificationLogs(setData);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_ALL_NOTIFICATIONS,
          uniqueScreenIdentifier: {
            allNotifications: false
          }
        })
      );
      setIsAllNotiLoading(false)
    }
  }, [state?.[STORE_KEYS.API_GET_ALL_NOTIFICATIONS]?.allNotifications]);

  const getHistoricalNotifications = (flag: boolean) => {
    setIsNotiLogsLoading(true)
    let defaultStartDate = new Date();
    defaultStartDate = new Date(defaultStartDate.setDate(defaultStartDate.getDate() - 7));
    const defaultEndDate = new Date();

    const sk: any = [], mk: any = [];
    historyFilteredSeverity.map((i: any) => sk.push(i.SeverityKey));
    historyFilteredMachines.map((i: any) => mk.push(i.MachineKey));
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_HISTORICAL_NOTIFICATIONS,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "RuleTypeKey": flag ? -1 : historyFilteredReportType,
            "SeverityKeys": !sk.length || flag ? '-1' : sk.toString(),
            "MachineKeys": !mk.length || flag ? '-1' : mk.toString(),
            "PlantKeys": notifilteredPlant.toString(),
            "StartDate": flag ? defaultStartDate : inDate,
            "EndDate": flag ? defaultEndDate : endDate
          }
        },
        uniqueScreenIdentifier: { histNotification: true },
        storeKey: STORE_KEYS.API_GET_HISTORICAL_NOTIFICATIONS
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_HISTORICAL_NOTIFICATIONS]?.histNotification) {
      const rptsData = state?.[STORE_KEYS.API_GET_HISTORICAL_NOTIFICATIONS]?.body?.data;
      const setData = rptsData?.map((rpt: any, index: any) => {
        const record = { ...rpt }
        return record;
      });

      setNotificationHistory(setData);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_HISTORICAL_NOTIFICATIONS,
          uniqueScreenIdentifier: {
            histNotification: false
          }
        })
      );
      setIsNotiLogsLoading(false)
    }
  }, [state?.[STORE_KEYS.API_GET_HISTORICAL_NOTIFICATIONS]?.histNotification]);

  const getRuleType = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_RULE_TYPES,
          reqObj: {
            "LoggedUserKey": loggedInUser,
          }
        },
        uniqueScreenIdentifier: { ruleTypeNotify: true },
        storeKey: STORE_KEYS.API_GET_RULE_TYPES
      })
    );
  };

  const getModuleFilterOptions = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'GET',
          urlPath: ACTION_CODES.API_GET_NOTIFICATION_FILTER_MODULE_LIST,
          reqObj: {
            "LoggedUserKey": loggedInUser,
          }
        },
        storeKey: STORE_KEYS.API_GET_NOTIFICATION_FILTER_MODULE_LIST
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_RULE_TYPES]?.ruleTypeNotify) {
      const rptsData = state?.[STORE_KEYS.API_GET_RULE_TYPES]?.body?.data;
      const setData = rptsData?.map((rpt: any, index: any) => {
        const record = { ...rpt }
        return record;
      });
      setRuleTypes([...setData?.map((item: any) => ({ code: item.RuleTypeKey, name: item.RuleTypeName }))])

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_RULE_TYPES,
          uniqueScreenIdentifier: {
            ruleTypeNotify: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_RULE_TYPES]?.ruleTypeNotify]);

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_NOTIFICATION_FILTER_MODULE_LIST]?.body?.data) {
      const grpsList = state?.[STORE_KEYS.API_GET_NOTIFICATION_FILTER_MODULE_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { ModuleCode: 'value', ModuleName: 'label' });
        return newVal;
      });
      setModuleFilterOptions(grpsList);
    }
  }, [state?.[STORE_KEYS.API_GET_NOTIFICATION_FILTER_MODULE_LIST]?.body?.data]);

  const fetchSeverity = (id: any) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_NOTIFICATION_SEVERITY,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "RuleTypeKey": id
          }
        },
        uniqueScreenIdentifier: { ruleSeverity: true },
        storeKey: STORE_KEYS.API_GET_NOTIFICATION_SEVERITY
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_NOTIFICATION_SEVERITY]?.ruleSeverity) {
      const rptsData = state?.[STORE_KEYS.API_GET_NOTIFICATION_SEVERITY]?.body?.data;
      const setData = rptsData?.map((rpt: any, index: any) => {
        const record = { ...rpt }
        return record;
      });
      setSeverity(setData);

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_NOTIFICATION_SEVERITY,
          uniqueScreenIdentifier: {
            ruleSeverity: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_NOTIFICATION_SEVERITY]?.ruleSeverity]);

  const getMachines = (plantKey: number) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_NOTIFICATION_MACHINES,
          reqObj: {
            "plantKey": plantKey,
          }
        },
        uniqueScreenIdentifier: { machinesNotification: true },
        storeKey: STORE_KEYS.API_GET_NOTIFICATION_MACHINES
      })
    );
  };
  const getNotiFilterPlantList = () => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'GET',
          urlPath: ACTION_CODES.API_GET_NOTIFICATION_FILTER_PLANT_LIST,
        },
        uniqueScreenIdentifier: { machinesNotification: true },
        storeKey: STORE_KEYS.API_GET_NOTIFICATION_FILTER_PLANT_LIST
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_NOTIFICATION_MACHINES]?.machinesNotification) {
      const rptsData = state?.[STORE_KEYS.API_GET_NOTIFICATION_MACHINES]?.body?.data;
      const setData = rptsData?.map((rpt: any, index: any) => {
        const record = { ...rpt }
        return record;
      });
      setMachines(setData)

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_NOTIFICATION_MACHINES,
          uniqueScreenIdentifier: {
            machinesNotification: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_NOTIFICATION_MACHINES]?.machinesNotification]);

  const handleChangeAck = (event: any, index: any, selectedOption: any) => {
    if (ackError.includes(index)) {
      const pos = ackError.indexOf(index);
      const data = [...ackError]
      data.splice(pos, 1);
      setAckError(data);
    }
    let data: any = [...ackSelectedNotification];
    const foundIndex = data.findIndex((x: any) => x.id == index);
    data[foundIndex === -1 ? data.length : foundIndex] = { id: index, value: event.target.value.toString(), option: selectedOption };
    setAckSelectedNotification(data);
  }

  const handleSaveAck = async (id: any, type: string, selectedOption: string) => {
    const result: any = ackSelectedNotification.filter((i: any) => i.id === id);
    if (result.length === 0 && selectedOption === '1' || result[0]?.value === "" && selectedOption === '1') {
      setAckError([...ackError, id]);
      return;
    }

    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_ACK_NOTIFICATION,
          reqObj: {
            "LoggedUserKey": loggedInUser,
            "CondAlertKey": parseInt(id),
            "AlertType": type,
            "RuleEngAckTypeKey": result.length ? parseInt(result[0].option) : 2,
            "RemarkAndActionTaken": result.length ? result[0].value : ''
          },

        },
        storeKey: STORE_KEYS.API_ACK_NOTIFICATION
      })
    );

    handleCancelAck(id);
    setCurrTab(1);
    setCreateDrawerOpen(false);
    getAllNotifications(true);
  }

  useEffect(() => {
    if (state?.[STORE_KEYS.API_ACK_NOTIFICATION]?.body?.data?.[0]?.Result == "Success") {
      setSnackbarOptions(options.AD_SUCCESS);
      getHistoricalNotifications(false)
    }
  }, [state?.[STORE_KEYS.API_ACK_NOTIFICATION]])

  const handleCancelAck = (id: any) => {
    const foundIndex = ackSelectedNotification.findIndex((x: any) => x.id == id);
    const data = [...ackSelectedNotification];
    data.splice(foundIndex, 1)
    setAckSelectedNotification(data);
  }

  const getNotificationDetails = (id: any, type: string) => {
    dispatch(
      executeACGAction({
        payload: {
          requestType: 'POST',
          urlPath: ACTION_CODES.API_GET_NOTIFICATION_DETAILS,
          reqObj: {
            "CondAlertKey": parseInt(id),
            "AlertType": type
          }
        },
        uniqueScreenIdentifier: { notificationDetails: true },
        storeKey: STORE_KEYS.API_GET_NOTIFICATION_DETAILS
      })
    );
  };

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_NOTIFICATION_DETAILS]?.notificationDetails) {
      const rptsData = state?.[STORE_KEYS.API_GET_NOTIFICATION_DETAILS]?.body?.data;
      const setData = rptsData?.map((rpt: any, index: any) => {
        const record = { ...rpt }
        return record;
      });
      setNotificationDetails(setData)

      dispatch(
        updateScreenIdentifiers({
          storeKey: STORE_KEYS.API_GET_NOTIFICATION_DETAILS,
          uniqueScreenIdentifier: {
            notificationDetails: false
          }
        })
      );
    }
  }, [state?.[STORE_KEYS.API_GET_NOTIFICATION_DETAILS]?.notificationDetails]);

  const navigateTab = () => {
    switch (currTab) {
      case 0:
        return <AllNotifications
          setCreateValue={setCreateValue}
          data={notificationLogs}
          clearFilter={clearFilter}
          appliedFilters={appliedFilters}
          setCreateDrawerOpen={setCreateDrawerOpen}
          setDrawerHeader={setDrawerHeader}
          setSelectedNotification={setSelectedNotification}
          selectedNotification={selectedNotification}
          ackSelectedNotification={ackSelectedNotification}
          setAckSelectedNotification={setAckSelectedNotification}
          handleCancelAck={handleCancelAck}
          handleSaveAck={handleSaveAck}
          handleChangeAck={handleChangeAck}
          notificationDetails={notificationDetails}
          getNotificationDetails={getNotificationDetails}
          setNotificationDetails={setNotificationDetails}
          setAckError={setAckError}
          ackError={ackError}
          isLoading={isAllNotiLoading}
          moduleFilterOptions={moduleFilterOptions}
          handleModuleFilterRadioValue={handleModuleFilterRadioValue}
          moduleFilterRadioValue={moduleFilterRadioValue}
        />
      case 2:
        return <NotificationGroup
          setCurrTab={setCurrTab}
          notificationData={notificationData}
          setNKey={setNKey}
          nKey={nKey}
          setSnackbarOpts={setSnackbarOptions}
          clearFilter={clearFilter}
          appliedFilters={appliedFilters}
          fetchNotificationGroups={fetchNotificationGroups}
          setCreateDrawerOpen={setCreateDrawerOpen}
          setDrawerHeader={setDrawerHeader}
          setCreateValue={setCreateValue}
          reload={reload}
          setReload={setReload}
          radioValue={radioValue}
          filteredCustomer={filteredCustomer}
          filteredModule={filteredModule}
          setFilteredCustomer={setFilteredCustomer}
          setFilterModule={setFilteredModule}
          isLoading={isNotiGroupLoading}
          
        />;
      case 1:
        return <NotificationLogs
          notificationData={notificationHistory}
          setNKey={setNKey}
          nKey={nKey}
          setSnackbarOpts={setSnackbarOptions}
          clearFilter={clearFilter}
          appliedFilters={appliedFilters}
          fetchNotificationGroups={fetchNotificationGroups}
          setCreateDrawerOpen={setCreateDrawerOpen}
          setDrawerHeader={setDrawerHeader}
          setCreateValue={setCreateValue}
          reload={reload}
          setReload={setReload}
          radioValue={radioValue}
          filteredCustomer={filteredCustomer}
          filteredModule={filteredModule}
          setFilteredCustomer={setFilteredCustomer}
          setFilterModule={setFilteredModule}
          setSelectedNotificationRow={setSelectedRow}
          isLoading={isNotiLogsLoading}
        />
      default:
        return <AllNotifications
          data={notificationLogs}
          clearFilter={clearFilter}
          appliedFilters={appliedFilters}
          setCreateDrawerOpen={setCreateDrawerOpen}
          setDrawerHeader={setDrawerHeader}
          setSelectedNotification={setSelectedNotification}
          selectedNotification={selectedNotification}
          ackSelectedNotification={ackSelectedNotification}
          setAckSelectedNotification={setAckSelectedNotification}
          handleCancelAck={handleCancelAck}
          handleSaveAck={handleSaveAck}
          notificationDetails={notificationDetails}
          getNotificationDetails={getNotificationDetails}
          setNotificationDetails={setNotificationDetails}
          setAckError={setAckError}
          ackError={ackError}
          fetchNotificationGroups={fetchNotificationGroups}
        />
    }
  };

  useEffect(() => {
    const fm: any = [];
    filteredModule.map((i: any) => { fm.push(i.code) });
    const body = { userId: loggedInUser, token: token, moduleKey: fm.toString() === "" ? '-1' : fm.toString() }
    NotificationService.getfilterCustomers(body)
      .then((response: any) => {
        const result = response?.data.message[0];
        const removeAll: any = [];
        result[0].map((i: any) => {
          if (i.CustomerName !== "All") {
            removeAll.push(i);
          }
        })
        setFilterCustomersData([
          ...removeAll.map((item: any) => ({ code: item.CustomerKey, name: item.CustomerName }))
        ]);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [filteredModule])

  useEffect(() => {
    fetchNotificationGroups(true);
    getAllNotifications(true);
    getHistoricalNotifications(true);
    getRuleType();
    getNotiFilterPlantList()
    getModuleFilterOptions()
    const body = { userId: loggedInUser, token: token }
    NotificationService.getCustomers(body)
      .then((response: any) => {
        const result = response?.data.message[0];
        const removeAll: any = [];
        result[0].map((i: any) => {
          if (i.CustomerName !== "All") {
            removeAll.push(i);
          }
        })
        setCustomersData([
          ...removeAll.map((item: any) => ({ code: item.CustomerKey, name: item.CustomerName }))
        ]);
      })
      .catch((err: any) => {
        console.log(err);
      });

    NotificationService.getModule(body)
      .then((response: any) => {
        const result = response?.data.message[0];
        setModules([
          ...result[0].map((item: any) => ({ code: item.NotifGrpModuleKey, name: item.NotifGrpModuleName }))
        ]);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const fetchNotificationGroups = (clear: boolean) => {
    setIsNotiGroupLoading(true)
    const fc: any = [], fm: any = [], p: any = [];
    filteredCustomer.map((i: any) => { fc.push(i.code) });
    filteredModule.map((i: any) => { fm.push(i.code) });
    filteredPlant.map((i: any) => { p.push(i.code) });
    const body = { userId: loggedInUser, customerId: clear || fc.toString() === "" ? -1 : fc.toString(), moduleId: clear || fm.toString() === "" ? -1 : fm.toString(), token: token, plantKey: clear || p.toString() === "" ? -1 : p.toString() }
    NotificationService.getNotificationGroups(body)
      .then((response: any) => {
        const result = response?.data.message[0];
        setNotificationData(result[0]);
      })
      .catch((err: any) => {
        console.log(err);
      }).finally(()=>{
        setIsNotiGroupLoading(false)
      })
  }

  const clearFilter: any = () => {
    setAppliedFilters(false);
    setFilteredCustomer([]);
    setFilteredModule([]);
    setFilteredPlant([]);
    let defaultStartDate = new Date();
    defaultStartDate = new Date(defaultStartDate.setDate(defaultStartDate.getDate() - 7));
    setInDate(defaultStartDate);
    setEndDate(new Date());
    setHistoryFilteredReportType(-1);
    setSeverity([]);
    setHistoryFilteredSeverity([]);
    setHistoryFilteredMachines([]);
    setFilteredReportType(-1);
    setNotiFilteredPlant(-1)
    setFilteredSeverity([]);
    setFilteredMachines([]);
    currTab === 0 ? getAllNotifications(true) : currTab === 2 ? fetchNotificationGroups(true) : getHistoricalNotifications(true);
  }

  const handleModulesName = (id: any) => {
    let newSelected: any = [];
    const selectedIndex = filteredModule.findIndex((t: any) => t.code === id.code);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(filteredModule, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(filteredModule.slice(1));
    } else if (selectedIndex === filteredModule.length - 1) {
      newSelected = newSelected.concat(filteredModule.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        filteredModule.slice(0, selectedIndex),
        filteredModule.slice(selectedIndex + 1)
      );
    }
    setFilteredModule(newSelected);
  }
  const handlePlantsName = (id: any) => {
    let newSelected: any = [];
    const selectedIndex = filteredPlant.findIndex((t: any) => t.code === id.code);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(filteredPlant, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(filteredPlant.slice(1));
    } else if (selectedIndex === filteredPlant.length - 1) {
      newSelected = newSelected.concat(filteredPlant.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        filteredPlant.slice(0, selectedIndex),
        filteredPlant.slice(selectedIndex + 1)
      );
    }
    setFilteredPlant(newSelected);
  }

  const handleCustomersName = (id: any) => {
    let newSelected: any = [];
    const selectedIndex = filteredCustomer.findIndex((t: any) => t.code === id.code);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(filteredCustomer, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(filteredCustomer.slice(1));
    } else if (selectedIndex === filteredCustomer.length - 1) {
      newSelected = newSelected.concat(filteredCustomer.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        filteredCustomer.slice(0, selectedIndex),
        filteredCustomer.slice(selectedIndex + 1)
      );
    }
    setFilteredCustomer(newSelected);
  }

  const handleChangeDropdown = (event: any) => {
    setHistoryFilteredReportType(event.target.value);
    setHistoryFilteredSeverity([]);
    fetchSeverity(event.target.value)
  }
  const handleChangeDropdownNotifications = (event: any) => {
    setFilteredReportType(event.target.value);
    setFilteredSeverity([]);
    fetchSeverity(event.target.value)
  }
  const handleNotificationsPlantsDropdown = (event: any) => {
    setNotiFilteredPlant(event.target.value);
    getMachines(event.target.value)
  }

  const handleSeverity = (id: any) => {
    let newSelected: any = [];
    const selectedIndex = historyFilteredSeverity.findIndex((t: any) => t.SeverityKey === id.SeverityKey);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(historyFilteredSeverity, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(historyFilteredSeverity.slice(1));
    } else if (selectedIndex === historyFilteredSeverity.length - 1) {
      newSelected = newSelected.concat(historyFilteredSeverity.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        historyFilteredSeverity.slice(0, selectedIndex),
        historyFilteredSeverity.slice(selectedIndex + 1)
      );
    }
    setHistoryFilteredSeverity(newSelected);
  }

  const handleMachines = (id: any) => {
    let newSelected: any = [];
    const selectedIndex = historyFilteredMachines.findIndex((t: any) => t.MachineKey === id.MachineKey);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(historyFilteredMachines, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(historyFilteredMachines.slice(1));
    } else if (selectedIndex === historyFilteredMachines.length - 1) {
      newSelected = newSelected.concat(historyFilteredMachines.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        historyFilteredMachines.slice(0, selectedIndex),
        historyFilteredMachines.slice(selectedIndex + 1)
      );
    }
    setHistoryFilteredMachines(newSelected);
  }

  const handleSeverityNotifications = (id: any) => {
    let newSelected: any = [];
    const selectedIndex = filteredSeverity.findIndex((t: any) => t.SeverityKey === id.SeverityKey);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(filteredSeverity, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(filteredSeverity.slice(1));
    } else if (selectedIndex === filteredSeverity.length - 1) {
      newSelected = newSelected.concat(filteredSeverity.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        filteredSeverity.slice(0, selectedIndex),
        filteredSeverity.slice(selectedIndex + 1)
      );
    }
    setFilteredSeverity(newSelected);
  }

  const handleMachinesNotifications = (id: any) => {
    let newSelected: any = [];
    const selectedIndex = filteredMachines.findIndex((t: any) => t.MachineKey === id.MachineKey);
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(filteredMachines, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(filteredMachines.slice(1));
    } else if (selectedIndex === filteredMachines.length - 1) {
      newSelected = newSelected.concat(filteredMachines.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        filteredMachines.slice(0, selectedIndex),
        filteredMachines.slice(selectedIndex + 1)
      );
    }
    setFilteredMachines(newSelected);
  }

  useEffect(() => {
    if (filteredCustomer) {
      let customer: any = [], module: any = []
      filteredCustomer.map((i: any) => { customer.push(i.code) });
      filteredModule.map((i: any) => { module.push(i.code) });
      dispatch(
        executeACGAction({
          payload: {
            requestType: 'POST',
            urlPath: ACTION_CODES.API_GET_NOTIFICATION_GROUP_FILTER_PLANT_LIST,
            reqObj: {
              "CustomerKey": customer.toString() === "" ? '-1' : customer.toString(),
              "ModuleKey": module.toString() === "" ? '-1' : module.toString()
            }
          },
          storeKey: STORE_KEYS.API_GET_NOTIFICATION_GROUP_FILTER_PLANT_LIST
        })
      );
    }
  }, [filteredCustomer])

  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_NOTIFICATION_GROUP_FILTER_PLANT_LIST]?.body?.data) {
      const grpsList = state?.[STORE_KEYS.API_GET_NOTIFICATION_GROUP_FILTER_PLANT_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
        return newVal;
      });
      setNotiGroupPlantList(grpsList);
    }

  }, [state?.[STORE_KEYS.API_GET_NOTIFICATION_GROUP_FILTER_PLANT_LIST]])
  useEffect(() => {
    if (state?.[STORE_KEYS.API_GET_NOTIFICATION_FILTER_PLANT_LIST]?.body?.data) {
      const grpsList = state?.[STORE_KEYS.API_GET_NOTIFICATION_FILTER_PLANT_LIST]?.body?.data?.map((item: any) => {
        const newVal = renameObjKeys(item, { PlantKey: 'code', PlantName: 'name' });
        return newVal;
      });
      setNotiFilterPlantList(grpsList);
    }

  }, [state?.[STORE_KEYS.API_GET_NOTIFICATION_FILTER_PLANT_LIST]])

  useEffect(()=>{
    getAllNotifications(true)
  },[moduleFilterRadioValue])

  return (
    <div className="dashboard">
      <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
      <Container maxWidth="xl" style={{ marginTop: '60px' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <div style={{ flex: '80%', display: 'flex' }}>
            <div className="headinglabel" style={{ marginTop: '5px' }}>
              Notifications
            </div>
            <div
              style={{
                flex: '10%',
                marginLeft: '20px',
                // marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
              className="dashboardDropdownDiv"
            >
              <DrawerDropdown click={getCreateValue} items={['Notification Group']} />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '35px', zIndex: '0' }}>
        </div>
        <div style={{ marginTop: '15px', zIndex: '0' }}>
          <MapCustomTabs
            navigateTab={navigateTab}
            currTab={currTab}
            setCurrTab={setCurrTab}
            tabsArray={tabs}
            className="tabs"
            position="relative"
          />
        </div>
      </Container>

      <RightDrawer
        handlerOpen={() => { }}
        handlerClose={() => { setCreateDrawerOpen(false); setDrawerHeader('Create'); setCreateValue('Notification Group'); setNKey("") }}
        status={createDrawerOpen}
        drawerHeader={`${drawerHeader} ${createValue}`}
        children={
          drawerHeader === 'Create' || drawerHeader === 'Edit' && createDrawerOpen ? (
            createValue === 'Notification Group' ?
              (<CreateNotificationGroup
                setCurrTab={setCurrTab}
                drawerClose={() => { setCreateDrawerOpen(false); }}
                setReload={setReload}
                setDtlData={setDtlData}
                dtlData={dtlData}
                setSnackbarOpts={setSnackbarOptions}
                isEdit={false}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setNKey={setNKey}
                nKey={nKey}
                customersData={customersData}
                modulesData={modulesData}
                fetchNotificationGroups={fetchNotificationGroups}
                notificationData={notificationData}
              />)
              : null
          ) : (

            <>
              {
                drawerHeader === "Add Member To" ?
                  (
                    <LoadMembers
                      notificationData={notificationData}
                      nKey={nKey}
                      fetchNotificationGroups={fetchNotificationGroups}
                      setSnackbarOpts={setSnackbarOptions}
                      setCurrTab={setCurrTab}
                      drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
                      setReload={setReload}
                      setDtlData={setDtlData}
                      dtlData={dtlData}
                      isEdit={false}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}

                    />)
                  : drawerHeader === "Filter" ?
                    (
                      <Filter
                        customersData={filterCustomerData}
                        modulesData={modulesData}
                        notificationGroupPlantList={notificationGroupPlantList}
                        notiFilterPlantList={notiFilterPlantList}
                        notificationData={notificationData}
                        setCurrTab={setCurrTab}
                        drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); }}
                        setReload={setReload}
                        setDtlData={setDtlData}
                        fetchNotificationGroups={fetchNotificationGroups}
                        setAppliedFilters={setAppliedFilters}
                        handleModulesName={(e: any) => handleModulesName(e)}
                        handlePlantsName={(e: any) => handlePlantsName(e)}
                        handleCustomersName={(e: any) => handleCustomersName(e)}
                        clearFilter={clearFilter}
                        setFilteredCustomer={setFilteredCustomer}
                        filteredCustomer={filteredCustomer}
                        filteredModule={filteredModule}
                        filteredPlant={filteredPlant}
                        appliedFilters={appliedFilters}
                        currTab={currTab}
                        setInDate={setInDate}
                        setEndDate={setEndDate}
                        inDate={inDate}
                        endDate={endDate}
                        handleChangeDropdown={handleChangeDropdown}
                        filteredReportType={filteredReportType}
                        ruleTypes={ruleTypes}
                        severity={severity}
                        filteredSeverity={filteredSeverity}
                        historyFilteredSeverity={historyFilteredSeverity}
                        handleSeverity={handleSeverity}
                        machines={machines}
                        historyFilteredMachines={historyFilteredMachines}
                        filteredMachines={filteredMachines}
                        handleMachines={handleMachines}
                        getHistoricalNotifications={getHistoricalNotifications}
                        historyFilteredReportType={historyFilteredReportType}
                        handleChangeDropdownNotifications={handleChangeDropdownNotifications}
                        getAllNotifications={getAllNotifications}
                        handleSeverityNotifications={handleSeverityNotifications}
                        handleMachinesNotifications={handleMachinesNotifications}
                        handleNotificationsPlantsDropdown={handleNotificationsPlantsDropdown}
                        notifilteredPlant={notifilteredPlant}
                      />)
                    : drawerHeader === "Acknowledge" ?
                      (
                        <AcknowledgeNotification
                          drawerClose={() => { setCreateDrawerOpen(false); setSelectedRow([]); setAckSelectedNotification([]) }}
                          setSelectedNotification={setSelectedNotification}
                          selectedNotification={selectedNotification}
                          ackSelectedNotification={ackSelectedNotification}
                          setAckSelectedNotification={setAckSelectedNotification}
                          handleCancelAck={handleCancelAck}
                          handleSaveAck={handleSaveAck}
                          notificationDetails={notificationDetails}
                          getNotificationDetails={getNotificationDetails}
                          setNotificationDetails={setNotificationDetails}
                          handleChangeAck={handleChangeAck}
                          selectedRow={selectedRow}
                        />
                      )
                      : null
              }
            </>
          )
        }

      />
    </div>
  );
};

export default Notifications;
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// material
import { Paper, Typography } from '@mui/material';
import CustomTable from '../../../../atoms/CustomTable/index';
import { Grid } from '@material-ui/core';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import Button from '@mui/material/Button';
import { Container, Card } from '@mui/material';
import Stack from '@mui/material/Stack';
import TemporaryDrawer from '../../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import useGetState from '../../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../../utils/helper';
import Axios from 'axios';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';
import SnackbarAlert from '../../../../atoms/Snackbar';
import Graph from './graph';
import GraphFBE from './graphFBE';
import { parse } from 'path';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { fDateTimeNew } from '../../../../utils/formatTime';
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Preset Alert Deleted',
        open: true,
        type: 'success'
    },
};

type Anchor = 'right';

const Users = (props: any) => {

    const {
        oeeMaster,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        setReload,
        reload,
        selectedRow,
        setSelectedRow,
        setCreateValue,
        setDrawerHeader,
        setEditDrawerOpen,
        closeSnackbar,
        appliedFilters,
        clearFilter,
        gbData,
        chart1Page,
        getGoldenBatchData,
        updatePage,
        totalPages,
        parameterName,
        selectedSerial,
        setDefVal,
        defVal,
        hSliderT,
        setHSliderT,
        setVSliderB,
        setVSliderT,
        vSliderB,
        vSliderT,
        selectedRecipe,
        selectedBatch,
        machineModelName
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [right, setRight] = useState(false);

    const [tempParam1, setTempParam1] = useState('');
    const [tempParam2, setTempParam2] = useState('');
    const [alerstData, setAlertsData] = useState([]);
    const [alarmsData, setAlarmsData] = useState([]);
    const [phaseList, setPhaseList] = useState<any>([]);
    const [uniquePhaseListUnsorted, setUniquePhaseListUnsorted] = useState<any>([]);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;

    const lastPage = totalPages;
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);

    function valuetext(value: any) {
        return `${value}`;
    }
    const chart1NextPageConditionCheck = lastPage > chart1Page ? true : false;

    const pagination = async (count: number, operator: string) => {
        if (machineModelName.includes("BQS")) {
            setDefVal([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data?.length])
            setHSliderT([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data?.length]);
            updatePage(chart1Page, operator, count);
        } else {
            setDefVal([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data?.length])
            setHSliderT([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data?.length]);
            updatePage(chart1Page, operator, count);
        }
    }

    useEffect(() => {
        if (state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data?.length) {
            const LL: any = [], UL: any = [], AV: any = [];
            state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data.map((i: any) => {
                LL.push(i.OptimumLowerLimit);
                UL.push(i.OptimumUpperLimit);
                AV.push(i.ActualValue)
            })
            setVSliderB(Math.min(...LL) < Math.min(...AV) ? Math.min(...LL) : Math.min(...AV));
            setVSliderT(Math.max(...LL) > Math.max(...AV) ? Math.max(...LL) : Math.max(...AV));
            setHSliderT([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data?.length])
            setDefVal([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data?.length])
        }
    }, [state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data])

    useEffect(() => {
        if (state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data?.length) {
            const LL: any = [], UL: any = [], AV: any = [], items: any = [];
            state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data.map((i: any) => {
                LL.push(i.OptimumLowerLimit);
                UL.push(i.OptimumUpperLimit);
                AV.push(i.ActualValue)
            })
            setVSliderB(Math.min(...LL) < Math.min(...AV) ? Math.min(...LL) : Math.min(...AV));
            setVSliderT(Math.max(...LL) > Math.max(...AV) ? Math.max(...LL) : Math.max(...AV));
            setHSliderT([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data?.length])
            setDefVal([0, state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data?.length < 100 ? 100 : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data?.length])
            const phases = state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data.map((item: any, index: number) => {
                items.push({ 'key': item?.PhaseKey, name: item?.PhaseName });
                return ({ key: item?.PhaseKey, color: item.PhaseColour, name: item?.PhaseName })
            });
            const indices: any = [];
            items.sort((a: any, b: any) => b.key - a.key).map((item: any,) => {
                indices.push(getIndices(items.sort((a: any, b: any) => b.key - a.key), 'key', item.key))
            });
            const countDuplicates = indices.reduce((acc: any, current: any) => {
                acc[current.key] = (acc[current.key] || 0) + 1;
                return acc;
            }, {});
            setUniquePhaseListUnsorted(Object.entries(countDuplicates).map(([key, count]) => ({ key: Number(key), count })).reverse())
            setPhaseList(phases);
        }
    }, [state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data]);

    const getIndices = (array: any, key: any, value: any) => {
        const startIndex = array.findIndex((item: any) => item[key] === value);
        const endIndex = array.length - 1 - [...array].reverse().findIndex(item => item[key] === value);

        return { startIndex, endIndex, key: value };
    };

    const minDistance = 0;

    const percentage = (partialValue: any, totalValue: any) => {
        let indexAtPercentage = Math.round((totalValue) * partialValue / 100);
        return (indexAtPercentage)
    }

    const handleChange2 = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minDistance);
                setDefVal([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setDefVal([clamped - minDistance, clamped]);
            }
        } else {
            const length = machineModelName.includes("BQS") ? state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data.length : state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data.length;
            setHSliderT([percentage(newValue[0], length), percentage(newValue[1], length)])
            setDefVal(newValue as number[]);
        }
    };

    return (
        <div className='pmec-table goldenBatchCls'>
            <div className='pmec-tableInner'>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => {
                                setEditDrawerOpen(true);
                                setCreateValue("");
                                setDrawerHeader("Filter");
                            }}
                            numSelected={selected?.length}
                            filterName={filterName}
                            filterButton={true}
                            customButton={false}
                            filterLogsButton={true}
                            appliedFilters={appliedFilters}
                            dashboard={true}
                            clearFilter={props.clearAppliedFilter}
                            parameterName={parameterName}
                            selectedRecipe={selectedRecipe}
                            selectedBatch={selectedBatch}
                            machineModelName={machineModelName}
                        />
                        <Grid container style={{ display: "flex", position: "relative", padding: "0 14px", marginTop: (props.appliedFilters) ? 0 : '-60px' }}>
                            <div className={`left-content ${props.appliedFilters && !right ? "notfilter" : "filterapplied"}`}>
                                <Grid item>

                                    {/* <div className='yaxisbar'>Range</div> */}
                                    <div className='yaxisbar'>{parameterName ? parameterName : ""}</div>
                                </Grid>
                                <Grid item>
                                    {machineModelName.includes("BQS") ?
                                        <Graph
                                            gbData={gbData}
                                            vSliderT={vSliderT}
                                            vSliderB={vSliderB}
                                            hSliderT={hSliderT}
                                            appliedFilters={appliedFilters}
                                            selectedSerial={selectedSerial}
                                            setAlertsData={setAlertsData}
                                            setAlarmsData={setAlarmsData}
                                            rightSlider={right}
                                        />
                                        :
                                        <GraphFBE
                                            gbData={gbData}
                                            vSliderT={vSliderT}
                                            vSliderB={vSliderB}
                                            hSliderT={hSliderT}
                                            appliedFilters={appliedFilters}
                                            selectedSerial={selectedSerial}
                                            setAlertsData={setAlertsData}
                                            setAlarmsData={setAlarmsData}
                                            rightSlider={right}
                                            phaseList={phaseList}
                                            uniquePhaseListUnsorted={uniquePhaseListUnsorted}
                                        />
                                    }
                                    <div className='divwrap gbDivWrap'>
                                        <div className='buttonwrap'
                                        // style={{ width: "50%", margin: "auto", float: "right" }}
                                        >
                                            <Button variant="contained" size="small"
                                                style={chart1NextPageConditionCheck ? { color: '#e0e0e0', textTransform: 'capitalize', lineHeight: '1', padding: '10px 10px', marginRight: "8px" } : { padding: '10px', marginLeft: "0px", marginRight: "8px" }}
                                                disabled={chart1NextPageConditionCheck ? false : true} onClick={() => { pagination(1, '+'); }}>
                                                Previous
                                            </Button>
                                            <Slider style={{ marginLeft: "4px", marginRight: "4px" }}
                                                getAriaLabel={() => 'Minimum distance'}
                                                value={defVal}
                                                onChange={handleChange2}
                                                valueLabelDisplay="auto"
                                                getAriaValueText={valuetext}
                                                disableSwap
                                                disabled={state?.[STORE_KEYS.GOLDEN_BATCH_DATA]?.body?.data.length || state?.[STORE_KEYS.GOLDEN_BATCH_DATA_FBE]?.body?.data.length > 0 ? false : true}
                                            />
                                            <Button variant="contained" size="small"
                                                style={chart1Page > 1 ? { color: '#e0e0e0', textTransform: 'capitalize', lineHeight: '1', padding: '10px 10px', marginLeft: "8px" } : { padding: '10px', marginLeft: "8px" }}
                                                disabled={chart1Page > 1 ? false : true} onClick={() => { pagination(1, '-') }}>
                                                Next
                                            </Button>
                                        </div>
                                        {/* <div className='xaxisbar'>Date</div> */}
                                    </div>
                                </Grid>
                            </div>
                            <div className='arrowSwap'>
                                {(appliedFilters && !right) && <ChevronRightIcon onClick={() => setRight(true)} />}
                                {right && <ChevronLeftIcon onClick={() => setRight(false)} />}
                            </div>
                            {(appliedFilters && !right) ?
                                <div className='right-content'>
                                    <Grid item>

                                        <Box sx={{ width: '100%', maxWidth: '100%', bgcolor: '#1d1e2c', padding: "12px", borderRadius: '8px' }}>
                                            <Typography style={{ color: "#ffffff", fontSize: "12px", fontWeight: "bold" }} >Alarm Details</Typography>
                                            <table style={{ border: "1px solid #817272", width: '100%' }}>
                                                <tr style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}><th style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}>Parameter Name</th><th style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}>Trigged Time</th></tr>
                                                {
                                                    alarmsData?.map((i: any) => {
                                                        return (
                                                            <tr style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}><td style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}>{i.ParamName}</td><td style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}>
                                                                {i.TriggerTime}
                                                            </td></tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </Box>
                                        <Box sx={{ width: '100%', maxWidth: 350, bgcolor: '#1d1e2c', padding: "12px", marginTop: "8px", borderRadius: '8px' }}>
                                            <Typography style={{ color: "#ffffff", fontSize: "12px", fontWeight: "bold" }} >Alerts Details</Typography>
                                            <table style={{ border: "1px solid #817272", width: '100%' }}>
                                                <tr style={{ color: "#ffffff", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}><th style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}>Parameter Name</th><th style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}>Trigged Time</th></tr>
                                                {
                                                    alerstData?.map((i: any) => {
                                                        return (
                                                            <tr style={{ color: "#ffffff", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}><td style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}>{i.AlertName}</td><td style={{ color: "#ffffff", fontWeight: "normal", fontSize: "12px", border: "1px solid #817272", padding: "4px" }}>
                                                                {/* {new Date(i.TriggerTime)} */}
                                                                {i.TriggerTime}
                                                            </td></tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </Box>
                                    </Grid>
                                </div>
                                : null}
                        </Grid>
                    </Card>

                </Container>

            </div>
        </div>
    );
};

export default Users;

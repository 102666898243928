import { useEffect, useRef, useState } from 'react';
import {
    Checkbox,
    Container,
    FormControl,
    ListItemText,
    MenuItem,
    Grid,
    Select,
    Typography,
    Box
} from '@mui/material';
import Axios from 'axios';
import useGetState from '../../../../utils/hooks/useGetState';
import { ACTION_CODES, STORE_KEYS, BASEURL } from '../../../../constants/apiConstants';
import DropDown from '../../../../atoms/Dropdown';
import ACGButton from '../../../../atoms/Button';
import { Formik, Form, FormikProps } from 'formik';
import Label from '../../../../atoms/Label/index';
import CustomDatePicker from '../../../../atoms/CustomDatePicker';
type ComponentProps = {
    drawerClose: Function;
    handleSubmit: Function;
    initialValues: any;
    setInitialValues: Function;
    setTemperatureFilters: Function;
    setAllFilters: Function;
};

type ModuleType = {
    plantInputValue: string | '';
    machineNameInputValue: string | '';
    batchInputValue: string | '';
    recipeInputValue: string | '';
    startDate: any,
    endDate: any,
};

const CreateModule = (props: ComponentProps) => {
    const [plantsList, setPlantsList] = useState<any[]>([]);
    const [machineNameList, setMachineNameList] = useState<any[]>([]);
    const [batchList, setBatchList] = useState<any[]>([]);
    const [recipeList, setRecipeList] = useState<any[]>([]);
    const [plantKey, setPlantKey] = useState('');
    const [machineNameKey, setMachineNameKey] = useState('');
    const [batchKey, setBatchKey] = useState('');
    const [recipeKey, setRecipeKey] = useState('');
    let defaultStartDate = new Date();
    defaultStartDate = new Date(defaultStartDate.setDate(defaultStartDate.getDate() - 7));
    const [tempStartDate, setTempStartDate] = useState(defaultStartDate);
    const [tempEndDate, setTempEndDate] = useState(new Date());
    const { drawerClose, initialValues, handleSubmit, setInitialValues, setAllFilters = () => { }, setTemperatureFilters = () => {} } = props;
    const formRef = useRef<any>(null);
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    useEffect(() => {
        getPlants();
    }, [])

    const getPlants = () => {
        const payload = {
            userId: loggedInUser,
            type: "plant"
        };
        Axios({
            url: BASEURL + '/powerbi/cpp-analysis-filter',
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setPlantsList([
                        ...tmpTableData?.map((item: any) => ({ code: item.PlantKey, name: item.PlantName }))
                    ]);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
    }
    const getMachines = (key: any) => {
        const payload = {
            userId: loggedInUser,
            plantKey: key,
            type: "machine_name"
        };
        Axios({
            url: BASEURL + '/powerbi/cpp-analysis-filter',
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setMachineNameList([
                        ...tmpTableData?.map((item: any) => ({ code: item.MachineKey, name: item.MachineName }))
                    ]);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
    }
    const getRecipies = (key: any, start: any, end: any) => {
        const payload = {
            userId: loggedInUser,
            machineModel: key,
            startDate: start,
            endDate: end,
            type: "recipe"
        };
        Axios({
            url: BASEURL + '/powerbi/cpp-analysis-filter',
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setRecipeList([
                        ...tmpTableData?.map((item: any) => ({ code: item.RecipeKey, name: item.ProductName }))
                    ]);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
    }
    const getBatches = (key: any) => {
        const payload = {
            userId: loggedInUser,
            machineModel: machineNameKey,
            recipeKey: key,
            startDate: tempStartDate,
            endDate: tempEndDate,
            type: "batch_no"
        };
        Axios({
            url: BASEURL + '/powerbi/cpp-analysis-filter',
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const tmpTableData = response.data.data;
                    setBatchList([
                        ...tmpTableData?.map((item: any) => ({ code: item.BatchKey, name: item.BatchNo }))
                    ]);
                }
            })
            .catch((error) => {
                return {
                    status: error.response.status
                };
            });
    }

    const handleChangeDropdown = (event: { target: { value: any; }; }, type: string) => {
        if (type === "plants") {
            setPlantKey(event.target.value);
            getMachines(event.target.value);
            setMachineNameKey('');
            setBatchKey('');
            setRecipeKey('');
        }
        else if (type === "machine") {
            let defaultStartDate = new Date();
            defaultStartDate = new Date(defaultStartDate.setDate(defaultStartDate.getDate() - 7));
            setMachineNameKey(event.target.value);
            setBatchKey('');
            setRecipeKey('');
            getRecipies(event.target.value, defaultStartDate, new Date);
        }
        else if (type === "recipe") {
            setRecipeKey(event.target.value);
            setBatchKey('');
            getBatches(event.target.value);
        }
        else {
            setBatchKey(event.target.value);
        }
    }
    const renderButtons = () => {
        return (
            <div className={'btns noWrap'}>
                <ACGButton
                    name={'Cancel'}
                    handleClick={drawerClose}
                    secondary={true}
                    className={'first-btn'}
                    type="button"
                    variant="contained"
                />
                <ACGButton
                    name={'Submit'}
                    handleClick={() => { }}
                    type="submit"
                    className="second-btn"
                    variant="contained"
                    disabled={machineNameKey === '' || batchKey === '' ? true : false}
                />
            </div>
        );
    };
    return (
        <Container className="createReport">
            <Formik
                initialValues={initialValues}
                onSubmit={() => {
                    handleSubmit(machineNameKey, batchKey);
                }}
                validateOnBlur={false}
                validateOnChange={false}
                innerRef={formRef}
            >
                {(formikprops: FormikProps<ModuleType>) => {
                    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = formikprops;
                    return (<Form className='cppClsMain'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Label label="Plants" className={'labelDiv mbtop'} />
                                <DropDown
                                    onChange={(e) => handleChangeDropdown(e, 'plants')}
                                    className="inputDiv"
                                    name="dropdownModel"
                                    items={plantsList}
                                    placeHolder="Select Plants"
                                    value={plantKey}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                <Label label="Machine Name" className={'labelDiv'} />
                                <DropDown
                                    onChange={(e) => handleChangeDropdown(e, 'machine')}
                                    className="inputDiv"
                                    name="dropdownModel"
                                    items={machineNameList}
                                    placeHolder="Select Machine"
                                    value={machineNameKey}
                                />
                            </Grid>
                            {machineNameKey !== '' ?
                                <>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Label label="Start Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="startDate"
                                            value={values.startDate}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setRecipeKey('');
                                                setTempStartDate(val);
                                                setFieldValue('startDate', val);
                                                getRecipies(machineNameKey, val, values.endDate);
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select Start Date"
                                            maxDate={new Date()}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Label label="End Date" className={'labelDiv'} />
                                        <CustomDatePicker
                                            name="endDate"
                                            value={values.endDate}
                                            className="inputDiv"
                                            onChange={(val: any) => {
                                                setRecipeKey('');
                                                setTempEndDate(val);
                                                setFieldValue('endDate', val);
                                                getRecipies(machineNameKey, values.endDate, val);
                                            }}
                                            inputFormat="dd/MM/yyyy"
                                            placeholder="Select Start Date"
                                            maxDate={new Date()}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Label label="Recipies" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(e) => handleChangeDropdown(e, 'recipe')}
                                            className="inputDiv"
                                            name="dropdownModel"
                                            items={recipeList}
                                            placeHolder="Select Recipe"
                                            value={recipeKey}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                                        <Label label="Batch No's" className={'labelDiv'} />
                                        <DropDown
                                            onChange={(e) => handleChangeDropdown(e, 'batch_no')}
                                            className="inputDiv"
                                            name="dropdownModel"
                                            items={batchList}
                                            placeHolder="Select Batch No"
                                            value={batchKey}
                                        />
                                    </Grid>
                                </>
                                : null}
                        </Grid>
                        {renderButtons()}
                    </Form>
                    )
                }}
            </Formik>
        </Container>
    );
};
export default CreateModule;

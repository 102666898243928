import { useState, useEffect } from 'react';
import { HashRouter, Switch, Route, Redirect, useHistory, useParams, useLocation } from 'react-router-dom';
import { acgSelector } from '../store/selector';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { executeACGAction, reset, updateScreenIdentifiers } from '../store/slice';
import Login from '../features/Login/index';
import ForgotPwd from '../features/ForgotPwd/index';
import { paths } from '../constants/paths';
import ClientManagement from '../features/Admin/ClientManagement/index';
import Navigation from '../atoms/NavigationDrawer/index';
import FeatureManagement from '../features/Admin/FeatureManagement';
import UserManagement from '../features/Admin/UserManagement';
import MachineMonitoring from '../features/MachineMonitoring';
import MachineDetail from '../features/MachineDetail';
import MachineManagement from '../features/MachineManagement';
import RemoteAssistance from '../features/RemoteAssistance/index';
import AlarmDetails from '../features/AlarmDetail';
import AddParams from '../features/AddParameters';
import EventDetails from '../features/EventDetail';
import PrivateRoute from './PrivateRoute';
import AuditTrail from '../features/AuditTrail';
import ReportDashboard from '../../app/features/UserReport';
import SystemConfiguration from '../features/SystemConfiguration';
import MaintenanceModule from '../features/MaintenanceModule';
import BCDashboard from '../features/Broadcast/Dashboard';
import DownloadableReport from '../features/UserReport/DownloadableReport';
import PageNotFound from '../atoms/PageNotFound';
import ConditionMonitoring from '../features/ConditionMonitoring';
import { ACTION_CODES, STORE_KEYS } from '../constants/apiConstants';
import RuleEngineService from '../features/ConditionMonitoring/ruleEngineServices';
import TrialAnalysis from '../features/TrialAnalysis';
import TrailGraph from '../features/TrialAnalysis/GraphPOC/index';
import FMEAAnalysis from '../features/FMEA';
import Notifications from '../features/Notifications';
import GoldenBatchDashboard from '../features/GoldenBatchDashboard';
import ComponentLifeCycle from '../features/ComponentLifeCycle';

// import TrailGraph from '../features/TrialAnalysis/GraphPOC/index';
import PMECDASHBOARD from '../features/OEEDashboard/OEEDashboardHome';
import PMECDASHBOARDKPI from '../features/OEEDashboard/OEEDetails';
import OEEDASHBOARDDT from '../features/OEEDashboard/OEEDowntimeDetails';

import Grafana from '../features/Grafana';
const Router = () => {
  let resetTime = 2 * 60;
  const [alertsData, setAlertsData] = useState<any[]>([]);
  const acgStateSelector = createStructuredSelector({
    acgSlice: acgSelector()
  });
  const { acgSlice: state } = useSelector(acgStateSelector);
  const dispatch = useDispatch();
  const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
  useEffect(() => {
    if (localStorage.getItem("token") !== null && state[STORE_KEYS.USER_DTL] !== undefined) {
      getUnacknowledgedNotificationCount()
      const interval = setInterval(() => getUnacknowledgedNotificationCount(), 60000);
      return () => clearInterval(interval);
    }
  }, [state[STORE_KEYS.USER_DTL]])

  useEffect(() => {
    if (localStorage.getItem("token") !== null && state[STORE_KEYS.USER_DTL] !== null) {
      getUnacknowledgedNotificationCount()
    }
  }, [state[STORE_KEYS.API_ACK_NOTIFICATION]])

  const getNotifications = async (id: any, token: any) => {
    const body = { userId: id, token: token }
    await RuleEngineService.getRuleEngineNotifications(body)
      .then((response: any) => {
        const result = response?.data?.message[0];
        setAlertsData(result[0])
      })
      .catch((err: any) => {
        console.log(err);
      });

  }

  const getUnacknowledgedNotificationCount = () => {
    const payload = {
      payload: {
        urlPath: ACTION_CODES.GET_UNACKNOWLEDGED_NOTIFICATION_COUNT,
        requestType: 'GET'
      },
      storeKey: STORE_KEYS.UNACKNOWLEDGED_NOTIFICATION_COUNT
    };
    dispatch(executeACGAction(payload));
  };

  useEffect(() => {
    window.onpopstate = e => {
      if (window.location.hash === "#/") {
        localStorage.removeItem('token');
        window.location.reload();
      }
    };
  }, [])

  return (
    <HashRouter basename="/">
      <Navigation alertsData={alertsData} />
      <Switch>
        <Route exact path={paths.LOGIN.path} component={Login} />
        <Route exact path={paths.FORGOT_PWD.path} component={ForgotPwd} />
        <PrivateRoute exact path="/client-management" component={ClientManagement} />
        <PrivateRoute exact path="/feature-management" component={FeatureManagement} />
        <PrivateRoute exact path="/client-management" component={ClientManagement} />
        <PrivateRoute exact path="/user-management" component={UserManagement} />
        <PrivateRoute exact path="/machine-monitoring" component={MachineDetail} />
        <PrivateRoute exact path="/machine-monitoring/machine-detail" component={MachineMonitoring} />
        <PrivateRoute exact path="/machine-monitoring/alarm-detail" component={AlarmDetails} />
        <PrivateRoute exact path="/machine-monitoring/event-detail" component={EventDetails} />
        <PrivateRoute exact path="/remote-assistance" component={RemoteAssistance} />
        <PrivateRoute exact path="/audit-trail" component={AuditTrail} />
        <PrivateRoute exact path="/user-reports" component={ReportDashboard} />
        <PrivateRoute exact path="/broadcast-management" component={BCDashboard} />
        <PrivateRoute exact path="/machine-management" component={MachineManagement} />
        <PrivateRoute exact path="/system-configuration" component={SystemConfiguration} />
        {/* <PrivateRoute exact path="/machine-detail" component={MachineDetail} />
                <PrivateRoute exact path="/alarm-detail" component={AlarmDetails} />
                <PrivateRoute exact path="/event-detail" component={EventDetails} /> */}
        <PrivateRoute exact path="/add-parameters" component={AddParams} />
        <PrivateRoute exact path="/downloads" component={DownloadableReport} />
        <PrivateRoute exact path="/maintenance-module" component={MaintenanceModule} />
        <PrivateRoute exact path="/condition-monitoring" component={ConditionMonitoring} />
        <PrivateRoute exact path="/trial-analysis" component={TrialAnalysis} />
        <PrivateRoute exact path="/fmea-analysis" component={FMEAAnalysis} />
        <PrivateRoute exact path="/oee-dashboard" component={PMECDASHBOARD} />
        <PrivateRoute exact path="/oee-dashboard/kpi" component={PMECDASHBOARDKPI} />
        <PrivateRoute exact path="/oee-dashboard-downtime/kpi" component={OEEDASHBOARDDT} />
        <PrivateRoute exact path="/notifications" component={Notifications} />
        <PrivateRoute exact path="/golden-batch-dashboard" component={GoldenBatchDashboard} />
        <PrivateRoute exact path="/component-life-cycle" component={ComponentLifeCycle} />
        <PrivateRoute exact path="/grafana" component={Grafana} />
        {/* <PrivateRoute exact path="/trial-analysis" component={TrailGraph} /> */}
        {/* <Route path="/goldenbatch-report">
          <TrailGraph />
        </Route> */}
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </HashRouter>
  );
};

export default Router;
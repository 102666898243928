import { Container } from '@mui/material';
import moment from 'moment';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Breadcrumb from '../../../atoms/BreadCrumb';
import Axios from 'axios';
import { ArcElement, Chart, registerables } from "chart.js";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ACTION_CODES, BASEURL, STORE_KEYS } from '../../../../app/constants/apiConstants';
import useGetState from '../../../../app/utils/hooks/useGetState';
import DownArrow from '../../../../assets/down-arrow.svg';
import BackArrowIcon from '../../../../assets/images/BackArrowIcon.svg';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import DropDown from '../../../atoms/Dropdown';
import SnackbarAlert from '../../../atoms/Snackbar';
import { formatOnlyDate } from '../../../utils/formatTime';
import '../../Admin/ClientManagement/index.scss';
import Treemap from "./GraphOEEKPI/treemap";
import ParetoChart1 from "./GraphOEEKPI/pareto";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
);

Chart.register(...registerables);
Chart.register(ArcElement);
const tabs = ['Machine List'];
const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    }
};
const SystemConfiguration = (props: any) => {
    const history = useHistory();
    const [currTab, setTabChange] = useState(0);
    const [radioValue, setRadioValue] = useState('All');
    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const date = new Date();
    date.setDate(date.getDate() - 14)
    const [inDate, setInDate] = useState(date);
    const d = new Date();
    const [endDate, setEndDate] = useState(new Date())
    const [defaultStartDate, setDefultStartDate] = useState(date);
    const [defaultEndDate, setDefaultEndDate] = useState(new Date());
    const [machineserialno, setMachineserialno] = useState<any>([]);
    const dispatch = useDispatch();
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const loggedInUser = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const [presetKey, setPresetKey] = useState('');
    const [machines, setMachines] = useState<any>([]);
    const [selectedMachine, setSelectedMachine] = useState<any>('');
    const [machineModels, setMachineModel] = useState<any>([]);
    const [selectedMachineModel, setSelectedMachineModel] = useState<any>('');
    const [products, setProducts] = useState<any>([]);
    const [batches, setBatches] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>([]);
    const [selectedBatch, setSelectedBatch] = useState<any>([]);
    const [shifts, setShifts] = useState<any>([]);
    const [selectedShift, setSelectedShift] = useState<any>([]);
    const [dtAnalysis, setDTAnalysis] = useState<any>([]);
    const [plannedDowntime, setPlannedDowntime] = useState<any>([]);
    const [unplannedDowntime, setUnplannedDowntime] = useState<any>([]);
    const [selectAllProduct, setSelectAllProduct] = useState<boolean>(false);
    const [selectAllBatch, setSelectAllBatch] = useState<boolean>(false);
    const [selectAllShift, setSelectAllShift] = useState<boolean>(false);
    const [search, setSearch] = useState<any>(props.location.search);
    const params = new URLSearchParams(search);
    const[value, setValue] = useState({
        plantKey: Number(params.get('pk')),
        machineKey: Number(params.get('mk')),
        machineModelKey: Number(params.get('mdk')),
        startDate: String(params.get('sd')),
        toDate: String(params.get('ed')), 
        batch: String(params.get('bk')),
        products: String(params.get('prk'))
   });

    const handleChange = (event: any) => {
        setSelectedMachineModel(event.target.value);
        fetchMachines(event.target.value, window.location.href.toString().split("&")[2].split("=")[1]);
        setSelectedProduct([]);
        setSelectedBatch([]);
        setSelectedShift([]);
        setSelectAllProduct(true);
        setSelectAllBatch(true);
        setSelectAllShift(true);
        setDTAnalysis([]);
        setUnplannedDowntime([]);
        setPlannedDowntime([]);
        setShifts([]);
        setBatches([]);
        setProducts([]);
    };

    const handleMachineChange = (event: any) => {
        setSelectedMachine(event.target.value);
        fetchDashboardProducts(event.target.value, inDate, endDate, selectedMachineModel);
        setSelectedProduct([]);
        setSelectedBatch([]);
        setSelectedShift([]);
        setSelectAllProduct(true);
        setSelectAllBatch(true);
        setSelectAllShift(true);
        setPlannedDowntime([]);
        setUnplannedDowntime([]);
        setDTAnalysis([]);
        setShifts([]);
        setBatches([]);
        setProducts([]);
    };

    useEffect(() => {

        (async () => {
            const paramData: any = window.location.href.toString()?.split("&");
            const body = {
                userId: loggedInUser,
            };
            await Axios({
                url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINE_MODEL,
                method: 'post',
                data: body,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then(async (response) => {
                    if (response.status == 200 && response?.data) {
                        const result = response?.data?.message;
                        const tmpTableData = await result[0][0];
                        setMachineModel([
                            ...tmpTableData?.map((item: any) => ({ code: item.MachineModelKey, name: item.MachineModelName }))])

                    }
                })
                .catch((error) => {
                    return {
                        status: error.response.status
                    };
                });
            setInDate(new Date(paramData[3]?.split("=")[1]));
            setEndDate(new Date(paramData[4]?.split("=")[1]));
            setSelectedMachineModel(parseInt(paramData[0].split("=")[1]));
            fetchMachines(parseInt(paramData[0].split("=")[1]), parseInt(window.location.href.toString().split("&")[2].split("=")[1]));
            setSelectedMachine(parseInt(paramData[1].split("=")[1]));
            const res = await fetchDashboardProducts(parseInt(paramData[1].split("=")[1]), new Date(paramData[3]?.split("=")[1]), new Date(paramData[4]?.split("=")[1]), parseInt(paramData[0].split("=")[1]));
            if (value.products !== 'null') {
                setSelectAllProduct(false);
                const data = res?.filter((i:any) => value.products.includes(i.ProductKey));
                setSelectedProduct(data)
            } else {
                setSelectAllProduct(true);
            }
            
            if (value.batch !== 'null') {
                setSelectAllBatch(false);
                setSelectedBatch([value.batch]);
            } else {
                setSelectAllBatch(true);
            }
            setSelectAllShift(true);
        })();

    }, []);

    const fetchMachines = async (mdk: any, pk: any) => {
        const payload = {
            userId: loggedInUser,
            model: mdk,
            plantKey: pk
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_MACHINES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setMachines([
                        ...tmpTableData?.map((item: any) => ({ code: item.MachineKey, name: item.MachineName }))])
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
    }

    const fetchDashboardProducts = async (mk: any, val1: any, val2: any, modelKey: any) => {
        let finalResult:any;
        const payload = {
            userId: loggedInUser,
            machineKey: mk,
            start: formatOnlyDate(val1),
            end: formatOnlyDate(val2),
            modelKey: modelKey
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_DASHBOARD_PRODUCTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setProducts(tmpTableData);
                    finalResult = tmpTableData;
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
            return finalResult;
    }
    const fetchBatches = async (val3: any) => {
        const payload = {
            userId: loggedInUser,
            machineKey: selectedMachine,
            modelKey: selectedMachineModel,
            start: formatOnlyDate(inDate),
            end: formatOnlyDate(endDate),
            productKeys: val3
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_BATCHES,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setBatches(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
    }

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);

    const handleChangeProduct = (id: any) => {
        setValue({...value, products: 'null'})
        setSelectAllShift(true);
        setShifts([]);
        setSelectedShift([]);
        setSelectAllBatch(true);
        setSelectAllShift(true);
        setPlannedDowntime([]);
        setUnplannedDowntime([]);
        setDTAnalysis([]);
        setShifts([]);
        setBatches([])
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedProduct.map((e:any) => e.ProductKey).indexOf(id.ProductKey);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedProduct, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedProduct.slice(1));
            } else if (selectedIndex === selectedProduct.length - 1) {
                newSelected = newSelected.concat(selectedProduct.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedProduct.slice(0, selectedIndex),
                    selectedProduct.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedProduct.findIndex((t: any) => t.ProductKey === id.ProductKey);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedProduct, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedProduct.slice(1));
            } else if (selectedIndex === selectedProduct.length - 1) {
                newSelected = newSelected.concat(selectedProduct.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedProduct.slice(0, selectedIndex),
                    selectedProduct.slice(selectedIndex + 1)
                );
            }
        }

        setSelectedProduct(newSelected);
        if (newSelected.length) {
            let pk: any = [];
            newSelected.map((item: any) => pk.push(item.ProductKey));
            fetchBatches(pk.toString());
        }

    }
    const handleChangeBatch = (id: any) => {
        setValue({...value, batch: 'null'})
        setSelectAllShift(true);
        setShifts([]);
        setSelectedShift([]);
        setPlannedDowntime([]);
        setUnplannedDowntime([]);
        setDTAnalysis([]);
        setShifts([]);
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedBatch.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedBatch.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedBatch, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedBatch.slice(1));
            } else if (selectedIndex === selectedBatch.length - 1) {
                newSelected = newSelected.concat(selectedBatch.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedBatch.slice(0, selectedIndex),
                    selectedBatch.slice(selectedIndex + 1)
                );
            }
        }
        setSelectedBatch(newSelected);
        if (newSelected.length) fetchShifts(newSelected);

    }

    const fetchShifts = async (sft: any) => {
        const btc: any = [];
        sft.map((i: any) => btc.push(i.BatchNo));
        const batchData = () => {
            if (value.batch !== 'null' && !selectAllBatch) {
                return value.batch
            } else {
                if (btc.length > 0) {
                    return btc.toString()
                } else { sft.map((i: any) => { return i.ShiftName }).toString() }
            }

        }
        const payload = {
            machineKey: selectedMachine,
            products: selectedProduct.map((i:any) => i.ProductKey).toString(),
            batches: batchData(),
            start: formatOnlyDate(inDate),
            end: formatOnlyDate(endDate),
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_SHIFTS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setShifts(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
    }
    const handleChangeShift = (id: any) => {
        setPlannedDowntime([]);
        setUnplannedDowntime([]);
        setDTAnalysis([]);
        let newSelected: any = [];
        if (presetKey === '') {
            const selectedIndex = selectedShift.indexOf(id);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedShift, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedShift.slice(1));
            } else if (selectedIndex === selectedShift.length - 1) {
                newSelected = newSelected.concat(selectedShift.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedShift.slice(0, selectedIndex),
                    selectedShift.slice(selectedIndex + 1)
                );
            }
        }
        else {
            const selectedIndex = selectedShift.findIndex((t: any) => t.BatchNo === id.BatchNo);
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedShift, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedShift.slice(1));
            } else if (selectedIndex === selectedShift.length - 1) {
                newSelected = newSelected.concat(selectedShift.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedShift.slice(0, selectedIndex),
                    selectedShift.slice(selectedIndex + 1)
                );
            }
        }

        setSelectedShift(newSelected);
        fetchKPIData(newSelected);

    }

    const fetchKPIData = async (sft: any) => {
        const shifts: any = [], batchs: any = [];
        sft.map((i: any) => shifts.push(i.ShiftName));
        selectedBatch.map((i: any) => batchs.push(i.BatchNo));
        const payload = {
            machineKey: selectedMachine,
            products:  selectedProduct.map((i:any) => i.ProductKey).toString(),
            batchs: batchs.length > 0 ? batchs.toString() : selectedBatch.map((i: any) => { return i.BatchNo }).toString(),
            shifts: shifts.length > 0 ? shifts.toString() : sft.map((i: any) => { return i.ShiftName }).toString(),
            start: formatOnlyDate(inDate),
            end: formatOnlyDate(endDate)
        };
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_DT_ANALYSIS,
            method: 'post',
            data: payload,
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setDTAnalysis(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_DT_DETAILS,
            method: 'post',
            data: { ...payload, DownTimeTypeCode: 'PDT' },
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setPlannedDowntime(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
        await Axios({
            url: BASEURL + '/' + ACTION_CODES.API_PMEC_GET_DT_DETAILS,
            method: 'post',
            data: { ...payload, DownTimeTypeCode: 'UPDT' },
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
            .then((response) => {
                if (response.status == 200 && response?.data) {
                    const result = response?.data?.message;
                    const tmpTableData = result[0][0];
                    setUnplannedDowntime(tmpTableData);
                }
            })
            .catch((error) => {
                return {
                    status: error
                };
            });
    }

    useEffect(() => {
        setPlannedDowntime([]);
        setUnplannedDowntime([]);
        setDTAnalysis([]);
        const sb: any = [];
        if (selectAllProduct && products || value.products !== "null") {
            products.map((i: any) => sb.push(i));
            setSelectedProduct(sb);
            const urlData = products?.filter((i:any) => value.products.includes(i.ProductKey));
            value.products !== "null" && !selectAllProduct ? setSelectedProduct(urlData) : setSelectedProduct(sb);
            if (sb.length > 0) fetchBatches('All')
        } else {
            setSelectedProduct([]);
        }
    }, [selectAllProduct, products]);

    useEffect(() => {
        setPlannedDowntime([]);
        setUnplannedDowntime([]);
        setDTAnalysis([]);
        const sb: any = [];
        if (selectAllBatch && batches || value.batch !== "null") {
            batches.map((i: any) => sb.push(i));
            setSelectedBatch(sb);
            if(value.batch !== "null" && !selectAllBatch) {
                setSelectedBatch(batches.filter((i: any) => i.BatchNo === value.batch))
            } else {
                setSelectedBatch(sb);
            }
            if (sb.length > 0) {
                fetchShifts(sb)
            }
        } else {
            setSelectedBatch([]);
        }
    }, [selectAllBatch, batches])

    useEffect(() => {
        const sb: any = [];
        if (selectAllShift && shifts) {
            shifts.map((i: any) => sb.push(i));
            setSelectedShift(sb);
            if (sb.length > 0) fetchKPIData(sb);

        } else {
            setSelectedShift([]);
            setPlannedDowntime([]);
            setUnplannedDowntime([]);
            setDTAnalysis([]);
        }
    }, [selectAllShift, shifts])

    return (
        <div className="dashboard">
            <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
            <Container maxWidth="xl" style={{ marginTop: '60px' }}>
                <div className="headinglabel mb10" style={{ marginBottom: '15px' }}>
                    <span className="backpage"><img
                        onClick={() => history.push("/oee-dashboard")}
                        style={{ cursor: 'pointer', height: '24px' }}
                        src={BackArrowIcon}
                        alt="Arrow" /></span>OEE Downtime</div>
                <Breadcrumb data={
                    [{ label: "Home", url: "/oee-dashboard" },
                    { label: "Production Dashboard", url: `/oee-dashboard/kpi?mdk=${value.machineModelKey}&mk=${value.machineKey}&pk=${value.plantKey}&sd=${moment.utc(defaultStartDate).format("MM/DD/YYYY")}&ed=${moment.utc(defaultEndDate).format("MM/DD/YYYY")}` },
                    { label: "OEE Downtime", url: `/oee-dashboard-downtime/kpi?mdk=${value.machineModelKey}&mk=${value.machineKey}&pk=${value.plantKey}&sd=${moment.utc(defaultStartDate).format("MM/DD/YYYY")}&ed=${moment.utc(defaultEndDate).format("MM/DD/YYYY")}` }]} />
                <div className='filterdiv production_dashboard'>

                    <div className='width_filter colorfixes'>
                        <div className='lableTitle'>Select Machine Model</div>
                        <DropDown
                            onChange={handleChange}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={machineModels}
                            placeHolder="Select Machine Model"
                            value={selectedMachineModel}
                        />
                    </div>
                    <div className='width_filter colorfixes'>
                        <div className='lableTitle'>Select Machine</div>
                        <DropDown
                            onChange={handleMachineChange}
                            className="inputDiv dropdownbx"
                            name="dropdownModel"
                            items={machines}
                            placeHolder="Select Machine"
                            value={selectedMachine}
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>From</div>
                        {/* <Label label="From" className={'CMlabelDiv'} /> */}
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                setInDate(val);
                                fetchDashboardProducts(selectedMachine, val, endDate, selectedMachineModel);
                                setBatches([])
                                setProducts([])
                                setSelectedBatch([]);
                                setSelectedShift([]);
                                setSelectedProduct([])
                                setSelectAllProduct(true);
                                setSelectAllBatch(true);
                                setSelectAllShift(true);
                                setPlannedDowntime([]);
                                setUnplannedDowntime([]);
                                setDTAnalysis([]);
                                setShifts([]);
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select From Date"
                            minDate={new Date("01-01-2023")}
                        />
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>To</div>
                        {/* <Label label="To" className={'CMlabelDiv'} /> */}
                        <CustomDatePicker
                            name="dateFromSelect"
                            value={endDate}
                            minDate={inDate}
                            maxDate={new Date()}
                            className="inputDiv"
                            onChange={(val: any) => {
                                setEndDate(val);
                                fetchDashboardProducts(selectedMachine, inDate, val, selectedMachineModel);
                                setBatches([]);
                                setProducts([])
                                setSelectedBatch([]);
                                setSelectedShift([]);
                                setSelectedProduct([])
                                setSelectAllProduct(true);
                                setSelectAllBatch(true);
                                setSelectAllShift(true);
                                setPlannedDowntime([]);
                                setUnplannedDowntime([]);
                                setDTAnalysis([]);
                                setShifts([]);
                            }}
                            inputFormat="dd/MM/yyyy"
                            placeholder="Select End Date"
                        />
                    </div>
                    <div className='width_filter selectproductdetails'>
                        <div className='lableTitle'>Select Products</div>
                        <Select
                            IconComponent={DropDownIcon}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className='muti_boxcheck'
                            label=""
                            multiple
                            fullWidth
                            value={selectedProduct}
                            sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                            placeholder='Please select Batches'
                            // input={<OutlinedInput />}
                            renderValue={(selected) => selected.map((x: any) => x.ProductName).join(', ')}
                            MenuProps={MenuProps}

                        >
                            <MenuItem value={"All"} onClick={() => { setSelectAllProduct(!selectAllProduct); }}>
                                <Checkbox
                                    checked={
                                        selectAllProduct
                                    }

                                />
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {products?.map((i: any) => (
                                <MenuItem value={i} onClick={() => handleChangeProduct(i)} className='ulSelectProduct'>
                                    <Checkbox
                                        checked={
                                            selectedProduct.findIndex((item: any) => item.ProductKey === i.ProductKey) >= 0
                                        }

                                    />
                                    <ListItemText primary={i.ProductName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Batch</div>
                        <Select
                            IconComponent={DropDownIcon}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className='muti_boxcheck'
                            label=""
                            multiple
                            fullWidth
                            value={selectedBatch}
                            sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                            placeholder='Please select Batches'
                            // input={<OutlinedInput />}
                            renderValue={(selected) => selected.map((x: any) => x.McBatchNo).join(', ')}
                            MenuProps={MenuProps}

                        >
                            <MenuItem value={"all"} onClick={() => { setSelectAllBatch(!selectAllBatch); params.delete("bk") }}>
                                <Checkbox
                                    checked={
                                        selectAllBatch
                                    }

                                />
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {batches?.map((i: any) => (
                                <MenuItem value={i} onClick={() => handleChangeBatch(i)} className='ulSelectBatchRecipe'>
                                    <Checkbox
                                        checked={
                                            selectedBatch.findIndex((item: any) => item.BatchNo === i.BatchNo) >= 0
                                        }

                                    />
                                    <ListItemText primary={i.McBatchNo} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className='width_filter'>
                        <div className='lableTitle'>Select Shift</div>

                        <Select
                            IconComponent={DropDownIcon}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className='muti_boxcheck'
                            label=""
                            multiple
                            fullWidth
                            value={selectedShift}
                            sx={{ marginBottom: "10px", backgroundColor: "#1d1e2c", borderRadius: 3, marginTop: '10px', color: "#b1c2df" }}
                            placeholder='Please select Batches'
                            // input={<OutlinedInput />}
                            renderValue={(selected) => selected.map((x: any) => x.ShiftName).join(', ')}
                            MenuProps={MenuProps}

                        >

                            <MenuItem value={"all"} onClick={() => setSelectAllShift(!selectAllShift)}>
                                <Checkbox
                                    checked={
                                        selectAllShift
                                    }

                                />
                                <ListItemText primary="Select All" />
                            </MenuItem>
                            {shifts?.map((i: any) => (
                                <MenuItem value={i} onClick={() => handleChangeShift(i)} className='ulSelectBatchRecipe'>
                                    <Checkbox
                                        checked={
                                            selectedShift.findIndex((item: any) => item.ShiftName === i.ShiftName) >= 0
                                        }

                                    />
                                    <ListItemText primary={i.ShiftName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className='graphcontainer heightgraphs'>
                     <div style={{width: "100%"}}>
                        <h3 className='titleGraph font14'>OEE Downtime Details</h3>
                        <div className='treemapcls'>
                            {dtAnalysis ? <Treemap dtAnalysis={dtAnalysis} /> : null}
                        </div>
                    </div>
                </div>

                <div className='graphcontainer heightgraphs'>
                    <div className='leftgraph'>
                        <h3 className='titleGraph font14' style={{color: "#e0e0e0"}}>Planned Downtime</h3>
                        <div>
                            <ParetoChart1 downtime={plannedDowntime} />
                        </div>
                    </div>
                    <div className='rightgraph'>
                        <h3 className='titleGraph font14'>Unplanned Downtime</h3>
                        <div>
                            <ParetoChart1 downtime={unplannedDowntime} />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default SystemConfiguration;

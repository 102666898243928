import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import Label from '../../../atoms/Label/index';
import TextInput from '../../../atoms/TextInput';
import dateFormat from 'dateformat';
import DownArrow from '../../../../assets/down-arrow.svg';
import DropDown from '../../../atoms/Dropdown';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Container from '@mui/material/Container';
import CustomDatePicker from '../../../atoms/CustomDatePicker';
import ACGButton from '../../../atoms/Button';
import "../gb.css";
import { Grid } from '@mui/material';
import { reset } from '../../../store/slice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Dateformat = {
  YYYY_MM_DD : 'yyyy-mm-dd',
  YYYY_MM_DD_HH_MM_SS : 'yyyy-mm-dd hh:mm:ss',
  YYYY_MM_DD_HH_MM_SS_L : 'yyyy-mm-ddTHH:MM:ss.l',
}

export default function CreateForm(props: any) {

  const DropDownIcon = (prop: any) => (
    <img style={{ marginRight: '5px', marginTop: '-2px' }} src={DownArrow} {...prop} />
  );
  const submitHandler = () => {
    props.drawerClose();
    props.setAppliedFilters(true);
    props.getGoldenBatchData(props.chart1Page);
  }

  let isDisabled: boolean;
  if(props.status === 'Active') {
    isDisabled = props.selectedBatch === "" || props.selectedSerial === "" || props.selectedRecipe === "" || props.selectedParameter === "" ? true : false;
  } else {
    isDisabled = props.from === "" || props.to === "" || props.selectedBatch === "" || props.selectedSerial === "" || props.selectedRecipe === "" || props.selectedParameter === "" ? true : false;

  }

  const renderButtons = () => {
    return (
      <div className={'btns noWrap'} style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <ACGButton
          name={'Cancel'}
          handleClick={props.drawerClose}
          secondary={true}
          className={'first-btn'}
          type="button"
          variant="contained"
        />
        <ACGButton
          name={'Submit'}
          handleClick={() => { submitHandler() }}
          type="submit"
          className="second-btn"
          variant="contained"
          disabled = {isDisabled ? true : false}
        />
      </div>
    );
  };

  const reset = () => {
    props.switchGraphFilter();
    props.setSelectedSerial("");
    props.setSelectedProduct("");
    props.setMonitoringProductData([]);
    props.setRecipeData([]);
    props.setBatchData([]);
    props.setSelectedRecipe('');
    props.setSelectedBatch('');
    props.setSelectedParameter('');
    props.setParameterData([]);
  }

  return (
    <Container className="createReport">
      <Box>
        <div className='formwrap all-labelcls'>
          <FormControl fullWidth>
            <>
              <Label label="Status *" className={'CMlabelDiv'} />

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group" className='radiolabls labcolor mbzero'

              >
                <FormControlLabel style={{ color: "#ffff" }} value="Active" control={<Radio onChange={(e) => {props.setStatus(e.target.value); reset(); props.getMachineSerial(e.target.value, props.from, props.to, props.plantKey, props.machineModelKey, props.machineModelName);}} checked={props.status === 'Active' ? true : false} />} label="Active" />
                <FormControlLabel style={{ color: "#ffff" }} value="Historical" control={<Radio onChange={(e) => {props.setStatus(e.target.value); reset(); props.getMachineSerial(e.target.value, props.from, props.to, props.plantKey, props.machineModelKey, props.machineModelName);}} checked={props.status === 'Historical' ? true : false} />} label="Historical" />
              </RadioGroup>
              { props.status === 'Historical' ?
              <>
              <label className='CMlabelDiv label-style'>From *</label>
              <CustomDatePicker
                name="dateFromSelect"
                value={props.from}
                maxDate={new Date()}
                className="inputDiv"
                onChange={(val: any) => {
                  props.setFrom(dateFormat(val, Dateformat.YYYY_MM_DD));
                  props.getMachineSerial(props.status ,dateFormat(val, Dateformat.YYYY_MM_DD), props.to, props.plantKey, props.machineModelKey, props.machineModelName);
                  props.setSelectedSerial('');
                  props.setSelectedProduct('');
                  props.setMonitoringProductData([]);
                }}
                inputFormat="dd/MM/yyyy"
                placeholder="Select From Date"
                minDate={new Date("01-01-2023")}

              />
              <div style={{marginBottom: 10}}>
              <label className='CMlabelDiv label-style'>To *</label>
              <CustomDatePicker
                name="dateFromSelect"
                value={props.to}
                minDate={props.from}
                maxDate={new Date()}
                className="inputDiv"
                onChange={(val: any) => {
                  props.setTo(dateFormat(val, Dateformat.YYYY_MM_DD));
                  props.getMachineSerial(props.status, props.from ,dateFormat(val, Dateformat.YYYY_MM_DD), props.plantKey, props.machineModelKey, props.machineModelName);
                  props.setSelectedSerial('');
                  props.setSelectedProduct('');
                  props.setMonitoringProductData([]);
                }}
                inputFormat="dd/MM/yyyy"
                placeholder="Select End Date"
              />
             </div>
              </>
              : null}
               <Label label="Select Line / Machine Serial Number *" className={'labelDiv mbzero'} />
              <DropDown
                onChange={(snEvent: any) => {
                   props.setSelectedSerial(snEvent.target.value);
                   props.fetchMontoringProducts(snEvent.target.value, props.machineModelName);
                   props.setRecipeData([]);
                   props.setBatchData([]);
                   props.setSelectedRecipe('');
                   props.setSelectedBatch('');
                   props.setMonitoringProductData([]);
                   props.setSelectedProduct('');
                   props.setSelectedParameter('');
                   props.setParameterData([]);
                }}
                className="inputDiv"
              name="dropdownSerialNo"
              items={props.serialData}
              placeHolder="Select Line / Serial Number"
              value={props.selectedSerial}
              />
              <div style={{marginTop: 10}}>
               <Label label="Select Product *" className={'labelDiv mbzero'} />
              <DropDown
                onChange={(snEvent: any) => {
                   props.setSelectedProduct(snEvent.target.value);
                   props.setRecipeData([]);
                   props.setBatchData([]);
                   props.setSelectedRecipe('');
                   props.setSelectedBatch('');
                   props.fetchRecipe(snEvent.target.value, props.machineModelName);
                   props.fetchParameters(props.selectedSerial, props.machineModelName);
                   props.setSelectedParameter('');
                   props.setParameterData([]);
                }}
                className="inputDiv"
              name="dropdownSerialNo"
              items={props.monitoringProductData}
              placeHolder="Select Product"
              value={props.selectedProduct}
              />
              </div>
              <Label label="Select Recipe *" className={'labelDiv'} />
              <DropDown
                onChange={(snEvent: any) => {
                   props.setSelectedRecipe(snEvent.target.value);
                   props.setBatchData([]);
                   props.setSelectedBatch('');
                   props.fetchBatch(snEvent.target.value, props.machineModelName);
                }}
                className="inputDiv"
              name="dropdownRecipe"
              items={props.recipeData}
              placeHolder="Select Recipe"
              value={props.selectedRecipe}
              />
              <Label label={props.machineModelName.includes("BQS") ? "Select Batch *" : "Select Lot *"} className={'labelDiv'} />
              <DropDown
                onChange={(snEvent: any) => {
                   props.setSelectedBatch(snEvent.target.value);
                }}
                className="inputDiv"
              name="dropdownBatch"
              items={props.batchData}
              placeHolder="Select Batch"
              value={props.selectedBatch}
              />
               <Label label="Select Parameter *" className={'labelDiv'} />
              <DropDown
                onChange={(snEvent: any) => {
                   props.setSelectedParameter(snEvent.target.value);
                }}
                className="inputDiv"
              name="dropdownParameter"
              items={props.parameterData}
              placeHolder="Select Parameter"
              value={props.selectedParameter}
              />
            </>
          </FormControl>
        </div>
      </Box>
      <Grid style={{ display: "flex", marginTop: 20 }}>
        {renderButtons()}
      </Grid>
    </Container>
  );
};

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CustomTable from '../../../../atoms/CustomTable/index';
import CustomTableToolbar from '../../../../atoms/CustomTableToolbar';
import { Container, Card } from '@mui/material';
import TemporaryDrawer from '../../../../atoms/tempDrawer';
import { ACTION_CODES, STORE_KEYS } from '../../../../constants/apiConstants';
import { executeACGAction, resetErr, resetStoreKey, updateScreenIdentifiers } from '../../../../store/slice';
import useGetState from '../../../../utils/hooks/useGetState';
import { applySortFilter } from '../../../../utils/helper';
import Axios from 'axios';
import SnackbarAlert from '../../../../atoms/Snackbar';
import AlertBox from '../../../../atoms/AlertBox';
import DetailDrawer from '../../../../atoms/DetailDrawer';
import { formatAccordionData } from '../../../../utils/formatData';
import ContactDetailDrawer from '../../../../atoms/ContactDetailDrawer';

const TABLE_HEAD = [
    { id: 'ProductName', label: 'Product Name', alignRight: false },
    { id: 'RecipeName', label: 'Recipe Name', alignRight: false },
    { id: 'ParamName', label: 'Parameter Name', alignRight: false },
    { id: 'OptimumMax', label: 'Optimum Max', alignRight: false },
    { id: 'OptimumMin', label: 'Optimum Min', alignRight: false },
    { id: 'GBLimitTypes', label: 'GB Limit Types', alignRight: false },
    { id: 'LastUpdated', label: 'Last Updated?', alignRight: false },
    // { id: 'Action', label: 'Action', alignLeft: true }
];

const options = {
    DEFAULT: {
        message: '',
        open: false,
        type: ''
    },
    RM_SUCCESS: {
        message: 'Quality Attribute Entry Deleted',
        open: true,
        type: 'success'
    },
};

type Anchor = 'right';

const QialityAttributes = (props: any) => {
    const {
        qualityAttributeList,
        radioValue,
        selectedClient,
        setDtlData,
        dtlData,
        setReload,
        reload,
        selectedRow,
        setSelectedRow,
        setCreateValue,
        setDrawerHeader,
        setCreateDrawerOpen: setEditDrawerOpen,
        setWorkOrderKey,
        componentLSKey,
        setNewTrialNotification,
        deleteCLC,
        appliedWOFilters,
        clearFilter,
        setAlertType,
        reportKey,
        setReportKey,
        setFilteredGoldenRecipeKey,
        isLoading
    } = props;

    const dispatch = useDispatch();
    const [drawerState, setDrawerState] = useState({
        right: false
    });
    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('TrialId');
    const [selected, setSelected] = useState<any>([]);
    const [filterName, setFilterName] = useState<string>('');
    const [tableData, setTableData] = useState<any[]>([]);
   
    const state = useGetState();
    const token = state?.[STORE_KEYS.USER_DTL].token;
    const getLoggedUserId = state[STORE_KEYS.USER_DTL]?.LoggedUserKey;
    const currentPath = useLocation();
    const accessData = state?.[STORE_KEYS.ROUTES_PRIVILEGES];
    const isAccess = (__data: any) => {
        return __data.filter((d: any) => d.path == currentPath?.pathname)?.[0]?.isEditable;
    };
    useEffect(() => {
        if (reload) {
            setReload(false);
            setTableData([]);
        }
    }, [reload]);

    const [snackbarOptions, setSnackbarOptions] = useState(options.DEFAULT);
    const [removeBxOpen, setRemoveBxOpen] = useState(false);
    const [rmUser, setRmUser] = useState(false);
    const [RNVKey, setRNVKey] = useState<any>();

    useEffect(() => {
        if (rmUser) {
            closeRmBx();
            props.deleteCLC(RNVKey)
            setRmUser(false);
        }
    }, [rmUser]);

    const closeRmBx = () => {
        setRemoveBxOpen(false);
    };
    const removeFn = async (row: any) => {
        const r = await { ...row }
        setSelectedRow(r);
        setRNVKey(r?.MCLCWorkOrderKey);
        setRemoveBxOpen(true);
    };

    const handleRequestSort = (event: any, property: any) => {
        const isAsc = orderBy === event && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(event);
    };

    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            const newSelecteds = [];
            for (let i = 0; i < qualityAttributeList.length; i++) {
                newSelecteds.push(qualityAttributeList[i].id);
            }
            const toRemove = qualityAttributeList.slice(page, qualityAttributeList.length);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (requestedBy: string) => {
        const selectedIndex = selected.indexOf(requestedBy);
        let newSelected: any[] | ((prevState: unknown[]) => unknown[]) = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, requestedBy);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setDrawerState({ ...drawerState, [anchor]: open });
    };

    const filteredList = applySortFilter(qualityAttributeList, order, filterName, orderBy);

    const handleFilterByName = (event: { target: { value: string } }) => {
        setPage(0);
        setFilterName(event.target.value);
        setOrderBy('WorkOrderID');
    };

    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const showRow = (row: any) => {
        // setSelectedRow(row);
        // setDrawerHeader('View');
        // setAlertType("");
    };

    const handleSnackbarError = (err: any) => {
        const errorMsg = err?.message || 'Internal Server error';
        const snackbarError = {
            message: errorMsg,
            type: 'reject',
            open: true
        };
        setSnackbarOptions(snackbarError);
    };

    useEffect(() => {
        if (state?.err) {
            handleSnackbarError(state?.err);
            dispatch(resetErr());
        }
    }, [state?.err]);

    const handleRoute = (row: any, ele: any) => {
        if (ele === 'Action') {
            setSelectedRow({ ...row });
            props.editTrail(row?.TrialKey);
        }
    };

    const closeSnackbar = () => setSnackbarOptions(options.DEFAULT);
    const removeMd = (ele: any) => {
        // setMdKey(ele?.ModuleKey);
        setRemoveBxOpen(true);
    };
    const resetInput = () => {
        setFilterName('');
    };

    useEffect(() => {
        if(filteredList.length > 0) {
            props.setClearFilter(true);
        }
    },[filteredList])

    return (
        <div className='parameterLimitupdate'>
            <div>
                <SnackbarAlert options={snackbarOptions} handleClose={closeSnackbar} />
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: '0px',
                        paddingRight: '0px'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '0px',
                            backgroundColor: '#2b2d42',
                            overflow: 'inherit'
                        }}
                    >
                        <CustomTableToolbar
                            drawerOpen={() => {
                                setAlertType("");
                                setEditDrawerOpen(true);
                                setDrawerHeader("");
                                setCreateValue("Parameter Limit Update Filter");
                            }}
                            numSelected={selected?.length}
                            filterName={filterName}
                            onFilterName={handleFilterByName}
                            resetInput={resetInput}
                            displayInput={true}
                            text={{
                                title: 'Parameter Limit Update ',
                                subTitle: `${filteredList?.length} Parameter Limit Update Count`
                            }}
                            filterButton={true}
                            customButton={false}
                            appliedFilters={clearFilter}
                            clearFilter={props.clearGBLimit}
                            setFilteredGoldenRecipeKey={setFilteredGoldenRecipeKey}
                            customEditButton={clearFilter ? true : false}
                            editFn={async (row: any, type: string) => {
                                setAlertType("Custom Alerts")
                                setSelectedRow(filteredList);
                                setDrawerHeader('Edit');
                                setEditDrawerOpen(true);
                                setCreateValue('Limit Config');
                            }}
                        />
                    </Card>
                </Container>
                <CustomTable
                    selectable={false}
                    headers= {TABLE_HEAD}
                    body={filteredList}
                    isLoading={isLoading}
                    filteredUsers={filteredList}
                    filterName={filterName}
                    selected={selected}
                    isTableImageReqd={true}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowChange={(event: string) => handleClick(event)}
                    rowCount={filteredList?.length}
                    onPageChange={handleChangePage}
                    page={page}
                    setPageNumber={setPage}
                    selectRow={showRow}
                    route={handleRoute}
                    isRequestPage={true}
                    link={true}
                    removeFn={removeFn}
                    {...(radioValue === 'Customer' && !selectedClient
                        ? { noDataText: 'Select Client to display users' }
                        : {})}
                    GBLimit = {true}
                    
                />
            </div>
            <AlertBox
                isOpen={removeBxOpen}
                handleClose={closeRmBx}
                leftBtnPress={closeRmBx}
                rightBtnPress={() => setRmUser(true)}
                modalHeader="Delete Component Work Order"
                modalContent="Are you sure you want to delete work order?"
            />

        </div>
    );
};

export default QialityAttributes;
